import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1, Input1 } from '../../components/Input';
import Loader from '../../components/Loader';
import url from '../../url';

function UseGoods(){
    const [data, setData] = useState({})
    const [use, setUse] = useState(0);
    const [useBy, setUseBy] = useState("");
    const [loader, setLoader] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(()=>{

        setLoader(true);
        fetch(`${url}/goods/${id}`,{mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            if(data.status === true){
                setData(data.data[0]);
            }
        });

    },[id]);


    function send(){
        fetch(`${url}/goods/${id}`, {method:"POST",body: JSON.stringify({use, useBy, prevUse:data.used, prevStock: (data.quantity), stock: (data.quantity - use), date: Date.now()}),mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                navigate(-1);
              }
        });

    }



    return(
        <>
            <Form1 title=" Update Goods Use">
                <div className=" flex gap-4 justify-center text-white">
                    <div>
                        <span className=" font-bold">Total: </span>
                        <span>{data.quantity + data.used} {data.unit}</span>
                    </div>
                    <div>
                        <span className=" font-bold">Used: </span>
                        <span>{data.used} {data.unit}</span>
                    </div>
                    <div>
                        <span className=" font-bold">In Stock: </span>
                        <span>{data.quantity } {data.unit}</span>
                    </div>
                </div>
                {
                    loader?<Loader />:<></>
                }
                <Input1 onChange={(e)=> setUse(e.target.value)} type="number" name="use" value={use}  placeholder="Enter used quantity:" label="Used Quantity:" id="use" />
                <Input1 onChange={(e)=> setUseBy(e.target.value)} type="text" name="use" value={useBy}  placeholder="Enter good used  person name:" label="Used By:" id="useBy" />
                <Button1  click={send} name="Save" />
            </Form1>
        </>
    )
}

export default UseGoods;