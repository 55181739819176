import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

function SideBar({min}){
    const [active, setActive] = useState(-1);
    const [route, setRoute] = useState([]);
    const{ role } = useAuth();

    function toggleActive(index){
        if(active === index){
            setActive(-1)
        }
        if(active !== index){
            setActive(index)
        }
    }

    useEffect(()=>{
        if(role === "Admin"){
            setRoute([
                {
                    title:'User Management',
                    icon: <i className="fa-solid fa-users-gear "></i>,
                    height: 120,
                    links:[
                        {name:'User',link:'/user'},
                        {name:'Chef',link:'/chef'},
                        {name:'Waiter',link:'/waiter'},
                        {name:'Customer',link:'/customer'},
                    ]
                },
                {
                    title:'Table Booking',
                    icon: <i className="fa-solid fa-receipt"></i>,
                    height: 60,
                    links:[
                        {name:'Add Booking',link:'/addBooking'},
                        {name:'All Booking',link:'/allBooking'},
                    ]
                },
                {
                    title:'Order Management',
                    icon: <i className="fa-solid fa-list-check "></i>,
                    height: 220,
                    links:[
                        {name:'New Order',link:'/newOrder'},
                        {name:'Complete Order',link:'/completeOrder'},
                        {name:'Cancel Order',link:'/CancelOrder'},
                        {name:'ALL Order',link:'/AllOrder'},
                        {name:'Table',link:'/table'},
                        {name:'Coupon',link:'/coupon'},
                        {name:'Reward',link:'/reward'},
                    ]
                },
                {
                    title:'Goods & Suppliers',
                    icon: <i className="fa-solid fa-carrot"></i>,
                    height: 125,
                    links:[
                        {name:'Suppliers',link:'/suppliers'},
                        {name:'Unit',link:'/unit'},
                        {name:'Goods',link:'/goods'},
                        {name:'Purchase',link:'/purchase'},

                    ]
                },
                {
                    title:'Food Management',
                    icon: <i className="fa-solid fa-bowl-rice "></i>,
                    height: 60,
                    links:[
                        {name:'Food Category',link:'/Category'},
                        {name:'Foods',link:'/food'},
                    ]
                },
                {
                    title:'Expense Management',
                    icon: <i className="fa-solid fa-filter-circle-dollar"></i>,
                    height: 60,
                    links:[
                        {name:'Expense Category',link:'/expenseCategory'},
                        {name:'Expense',link:'/expense'},
                    ]
                },
                {
                    title:'Accessories Management',
                    icon: <i className="fa-solid fa-kitchen-set"></i>,
                    height: 60,
                    links:[
                        {name:'Accessories',link:'/accessories'},
                        {name:'Add Accessories',link:'/addAccessories'},
                    ]
                },
                // {
                //     title:'Settings',
                //     icon: <i className="fa-solid fa-gear"></i>,
                //     height: 60,
                //     links:[
                //         {name:'Web Settings',link:'/webSettings'},
                //         {name:'Invoice Settings',link:'/invoiceSettings'},
                //     ]
                // },
                {
                    title:'Reports',
                    icon: <i className="fa-solid fa-chart-line"></i>,
                    height: 250,
                    links:[
                        {name:"Today's Sale",link:'/todaySale'},
                        {name:'This Week Sale',link:'/thisWeekSale'},
                        {name:'This Month Sale',link:'/thisMonthSale'},
                        {name:'This Year Sale',link:'/thisYearSale'},

                        {name:"Today's Expense",link:'/toDayExpense'},
                        {name:'This Week Expense',link:'/thisWeekExpense'},
                        {name:'This Month Expense',link:'/thisMonthExpense'},
                        {name:'This Year Expense',link:'/thisYearExpense'},


                    ]
                }
            ]);
        }else if(role === "Manager"){
            setRoute([
                {
                    title:'Table Booking',
                    icon: <i className="fa-solid fa-receipt"></i>,
                    height: 60,
                    links:[
                        {name:'Add Booking',link:'/addBooking'},
                        {name:'All Booking',link:'/allBooking'},
                    ]
                },
                {
                    title:'Manage Order',
                    icon: <i className="fa-solid fa-list-check "></i>,
                    height: 153,
                    links:[
                        {name:'New Order',link:'/newOrder'},
                        {name:'Complete Order',link:'/completeOrder'},
                        {name:'Cancel Order',link:'/CancelOrder'},
                        {name:'ALL Order',link:'/AllOrder'},
                    ]
                }
            ]);
        }
    },[role])

 


    return(
    <>
    {
        !min?
        <div className=" w-[300px] min-h-screen hidden md:block bg-sidebar1 float-left select-none">
            <div className=" transition-all duration-700 ease-in-out w-full h-[70px] px-4 font-bold text-2xl text-main bg-slate-700 flex items-center gap-4">
                <Link to='/'>
                    <i className="fa-solid fa-burger text-4xl"></i>
                    <span> Good Food</span>
                </Link>
            </div>
            <div className=" mt-8">
                <NavLink className={(e)=>e.isActive?" text-main ":" hover:text-main text-slate-300"} to='/'>
                    <div className="  transition-all duration-700 ease-in-out  w-full ">
                        <div className=" text-xl cursor-pointer px-4 py-2 flex items-center gap-4"> <i className="fa-solid fa-chart-column"></i> <span>Dashboard</span></div>
                    </div>
                </NavLink>
                {
                    route.map((data, index)=>{
                        return(
                            <div key={index} className="  transition-all duration-700 ease-in-out  w-full ">
                                <div onClick={()=> toggleActive(index)} className="text-slate-300 text-xl cursor-pointer px-4 py-2 flex items-center gap-4"> {data.icon} <span>{data.title}</span></div>
                                <div style={{height:`${(active === index)? `${data.height}px`: '0px'} `}} className="transition-all duration-700 ease-in-out overflow-hidden h-10  pl-14  flex flex-col gap-2 bg-slate-800 shadow-inner text-slate-400 relative">
                                    <div className=" absolute top-0 left-0 w-1 h-full bg-main rounded-tr-md rounded-br-md"></div>
                                    {
                                        data.links.map((data, index)=>{
                                            return(
                                                <NavLink className={(e)=>e.isActive?" text-main":" hover:text-main"} key={index} to={data.link}>{data.name}</NavLink>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
        :
        <div onMouseLeave={()=>setActive(-1)} className=" hidden md:block w-[70px] min-h-screen bg-sidebar1 float-left select-none">
            <div className=" transition-all duration-700 ease-in-out w-full h-[70px] px-4 font-bold text-2xl text-main bg-slate-700 flex items-center gap-4">
                <Link to='/'><i className="fa-solid fa-burger text-4xl"></i></Link>
            </div>
            <div className=" mt-5">
                <NavLink className={(e)=>e.isActive?" text-main ":" hover:text-main text-slate-300"} to='/'>
                    <div className="  transition-all duration-700 ease-in-out  w-full ">
                        <div className=" text-xl cursor-pointer px-4 py-2 flex items-center gap-4"> <i className="fa-solid fa-chart-column"></i> <span></span></div>
                    </div>
                </NavLink>
                {
                    route.map((data, index)=>{
                        return(
                            <div key={index} className=" mt-2 transition-all duration-700 ease-in-out  w-full relative">
                                <div onClick={()=> toggleActive(index)} className={`${(active === index)?' text-main shadow-inner':'text-slate-300'} shadow-black  text-2xl cursor-pointer px-4 py-2 flex items-center gap-4 `}> {data.icon}</div>
                                <div style={{height:`${data.height+20}px`, marginLeft:`${(active === index)? '72px': '-500px'}`}} className={"transition-all duration-700 ease-in-out w-[200px] absolute top-3 left-0 -z-10 h-10 p-3 rounded-md  flex flex-col gap-2 bg-sidebar1 text-slate-400"}>

                                    {
                                        data.links.map((data, index)=>{
                                            return(
                                                <NavLink className={(e)=>e.isActive?" text-main":" hover:text-main"} key={index} to={data.link}>{data.name}</NavLink>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    }
    <div onMouseLeave={()=>setActive(-1)} className=" md:hidden w-[30px] min-h-screen bg-sidebar1 float-left select-none">
            <div className=" transition-all duration-700 ease-in-out w-full h-[70px] px-1 font-bold text-2xl text-main bg-slate-700 flex items-center gap-4"> 
                <Link to='/'><i className="fa-solid fa-burger text-2xl"></i></Link>
            </div>
            <div className=" mt-5">
                <NavLink className={(e)=>e.isActive?" text-main ":" hover:text-main text-slate-300"} to='/'>
                    <div className="  transition-all duration-700 ease-in-out  w-full ">
                        <div className=" text-lg cursor-pointer px-1 py-2 flex items-center gap-4"> <i className="fa-solid fa-chart-column"></i> <span></span></div>
                    </div>
                </NavLink>
                {
                    route.map((data, index)=>{
                        return(
                            <div key={index} className=" mt-2 transition-all duration-700 ease-in-out  w-full relative">
                                <div onClick={()=> toggleActive(index)} className={`${(active === index)?' text-main shadow-inner':'text-slate-300'} shadow-black  text-lg cursor-pointer px-1 py-2 flex items-center gap-4 `}> {data.icon}</div>
                                <div style={{height:`${data.height+20}px`, marginLeft:`${(active === index)? '32px': '-500px'}`}} className={"transition-all duration-700 ease-in-out w-[200px] absolute top-3 left-0 -z-10  h-10 p-3 rounded-md  flex flex-col gap-2 bg-slate-800 text-slate-400"}>

                                    {
                                        data.links.map((data, index)=>{
                                            return(
                                                <NavLink className={(e)=>e.isActive?" text-main":" hover:text-main"} key={index} to={data.link}>{data.name}</NavLink>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    </>
    );
}

export default SideBar;