import React from 'react';
import useAuth from '../hooks/useAuth';
import { Link1 } from './Button';

function NavLInkHome(){
    const { role } = useAuth();
    return(
        <>
        {
            (role === "Admin")?
            <>
                <Link1 to='/pos' >
                    <i className="fa-solid fa-table-cells-large text-xl"> </i>
                    <span className='hidden md:inline-block pl-2 text-xl'> POS</span>
                </Link1>
                <Link1  to='/newOrder' >
                    <i className="fa-solid fa-clipboard-list text-xl"></i> 
                    <span className='hidden md:inline-block pl-2 text-xl'> New Orders</span>
                </Link1>
                <Link1 to='/kitchen' >
                    <i className="fa-solid fa-fire-burner text-xl"></i>
                    <span className='hidden md:inline-block pl-2 text-xl'> Kitchen</span>
                </Link1>
                <Link1 to='/Counter' > 
                    <i className="fa-solid fa-cash-register text-xl"></i> 
                    <span className='hidden md:inline-block pl-2 text-xl'> Counter</span>
                </Link1>
            </>:<></>
        }

        {
            (role === "Manager")?
            <>
                <Link1 to='/pos' >
                    <i className="fa-solid fa-table-cells-large text-xs md:text-xl"></i>
                    <span className=' text-xs md:text-xl'> POS</span>
                </Link1>
                <Link1  to='/newOrder' >
                    <i className="fa-solid fa-clipboard-list text-xs md:text-xl"></i> 
                    <span className=' text-xs md:text-xl'> New Orders</span>
                </Link1>
                <Link1 to='/Counter' > 
                    <i className="fa-solid fa-cash-register text-xs md:text-xl"></i> 
                    <span className=' text-xs md:text-xl'> Counter</span>
                </Link1>
            </>:<></>
        }
        
    </>
    );
}

export { NavLInkHome };