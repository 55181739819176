import { useCookies } from 'react-cookie';

function useAuth(){
    const [cookies] = useCookies(['auth']);

    if(cookies.auth === undefined){
        return({status:false, role:""});
    }else{
        if(cookies.auth.login === true){
            return({status:true, id:cookies.auth.id, name:cookies.auth.name, role:cookies.auth.role, img:cookies.auth.img})
        }else{
            return({status:false});
        }
    }
}

export default useAuth;