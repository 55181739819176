import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Invoice } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url';

function Purchase() {

const [purchase, setPurchase] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);



// function delet(id){
//     fetch(`${url}/purchase/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
//         if(data.status === true){
//             if(src === ''){
//                 setLoader(true);
//                 fetch(`${url}/purchase?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
//                     setLoader(false);
//                     if(data.status === true){
//                         setPurchase(data.result);
//                     }
//                 });
//             }else{
//                 setLoader(true);
//                 fetch(`${url}/purchase/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
//                     setLoader(false);
//                     if(data.status === true){
//                         setPurchase(data.result);
//                     }
//                 });
                 
//             }
//         }else{

//         }
//     });
// }
useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/purchase?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setPurchase(data.result);
            }
        });
    }

    return () => setPurchase({data:[],next:{},previous:{}});

},[page,src, limit]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/purchase/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setPurchase(data.result);
            }
        });
    
    }

    return () => setPurchase({data:[],next:{},previous:{}});
},[page, limit, src]);

function search(e){
    setSrc(e.target.value);
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="/purchaseGoods" name="Purchase Goods" rowNames={["#","Payable","Paid", "Due","Supplier Name", "Supplier Phone", "Supplier Email", "Date", "Actions"]} srcVal={src} srcFunc={search} data={purchase} width='w-full'>
                {
                    purchase.data.map(({_id, payable, paid, due, supplierName, supplierPhone, supplierEmail, date }, index)=>{
                        return(
                            <Tr key={index}>
                                <Td>{((purchase.previous.page*purchase.previous.limit)+1)+index}</Td>
                                <Td>{payable}</Td>
                                <Td>{paid}</Td>
                                <Td>{due}</Td>
                                <Td>{supplierName}</Td>
                                <Td>{supplierPhone}</Td>
                                <Td>{supplierEmail}</Td>
                                <Td>{new Date(date).toLocaleString()}</Td>
                                <Td>
                                    <Invoice to={`/purchase/invoice/${_id}`} />
                                </Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
}</>)
}

export default Purchase;