import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import url from '../../url';

function Invoice(){

    const [purchase, setPurchase] = useState({_id:'', cart:[], customer:{}, receivable:0, received:0, due:0, customerName:"", customerEmail:"", customerPhone:"", customerAddress:"", totalPrice: 0, discount:0, quantity:0, date:''});
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(()=>{

        fetch(`${url}/sale/${id}`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{

            setPurchase(data.data[0]);


            setTimeout(() => {
                window.print();
                navigate(-1)
            }, 500);
            
        });

    },[id, navigate]);




    return(
        <div className=" p-4">
            <h2 className=" text-center font-bold text-[10px]"> Tehari n Hurry Restaurent </h2>
            <div>
                <h3 className=" flex flex-col text-center text-[8px]">
                    <span className=" font-bold">Branch-1</span>
                    <span>Block-E, Road-01,House-01 Rampura, Banasree, Dhaka.</span>
                </h3>
                <h3 className=" flex flex-col text-center text-[8px]">
                    <span className=" font-bold">Branch-2</span>
                    <span>Block-F, Road-04,House-02 Rampura, Banasree, Dhaka.</span>
                </h3>
            </div>
            <h3 className=" text-center text-[8px]"><span className="font-bold ">Phone No: </span> 01925586095<span></span></h3>
            <h3 className=" flex justify-between">
            <span className=" text-left text-[8px]">Date: {new Date(purchase.date).toLocaleDateString()}</span> 
            <span className=" float-right text-[8px]">Time: {new Date(purchase.date).toLocaleTimeString()}</span>
            </h3>
            <h1 className=" font-bold text-center text-[8px]"><span>Token:</span> <span>{purchase.token}</span></h1>
            <div className=" border-y border-dashed border-slate-400 text-[8px]"></div>
            <h1 className=" text-center font-bold text-[8px]">Customer</h1>
            <div className=" border-b border-dashed border-slate-400 text-[8px]"></div>
            <div className=" flex flex-wrap gap-x-2 border-t border-dashed border-slate-400 text-[8px]">
                <span className=" whitespace-nowrap"><span className="font-bold text-[8px]">Name :</span> {purchase.customerName} </span> 
                {
                    (purchase.customerEmail === "")?<></>:<span className=" whitespace-nowrap text-[8px]"><span className="font-bold text-[8px]">Email:</span> {purchase.customerEmail} </span>
                } 
                {
                    (purchase.customerPhone === "")?<></>:<span className=" whitespace-nowrap text-[8px]"><span className="font-bold text-[8px]">Phone:</span> {purchase.customerPhone} </span>
                } 
                 
            </div>
            <h1 className=" text-center font-bold border-b-2  border-dashed border-slate-400 text-[8px]">Paid Bill</h1>
            <table className=" w-full table-auto border-collapse ">
                        <thead>
                            <tr className=" text-left border-slate-400 border-b border-dashed">
                                <td className=" text-[8px]">Item Name</td>
                                <td className=" text-[8px]">Price</td>
                                <td className=" text-[8px]">Vat/Tax</td>
                                <td className=" text-[8px]">Quantity</td>
                                <td className=" text-[8px]">Total</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                purchase.cart.map((data,index)=>{
                                    return(
                                    <tr key={index} className=" border-slate-400 border-b border-dashed">
                                    <td className=" text-[8px]">{data.name}</td>
                                    <td className=" text-[8px]">{data.price}  </td>
                                    <td className=" text-[8px]">{data.vat?purchase.vat:0}%</td>
                                    <td className=" text-[8px]">{data.quantity}</td>
                                    <td className=" text-[8px]">{data.vat?((data.price * data.quantity) + ((data.price * data.quantity * purchase.vat)/100)):(data.price * data.quantity)}৳</td>
                                </tr>)
                                })
                            }

                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className=" text-left text-[8px]">Service Charge:</th>
                                <td className=" text-[8px]">{purchase.serviceCharge}৳</td>
                            </tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="  text-left text-[8px]">Total :</th>
                                <td className=" text-[8px]">{purchase.totalPrice}৳</td>
                            </tr>

                            <tr className=" border-b-[24px] border-transparent "></tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="  text-left text-[8px]">Discount :</th>
                                <td className=" text-[8px]">{purchase.discount}৳</td>
                            </tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="  text-left text-[8px]">Payable :</th>
                                <td className=" text-[8px]">{purchase.totalPrice - purchase.discount}৳</td>
                            </tr>
                            <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className=" text-left text-[8px]">Paid :</th>
                                <td className=" text-[8px]">{purchase.payed}৳</td>
                            </tr>
                            {/* <tr >
                                <td></td>
                                <td></td>
                                <th className="  text-left">Due :</th>
                                <td className=" ">{purchase.due - purchase.payed - purchase.discount}  ৳</td>
                            </tr> */}
                        </tbody>
                    </table>
            <div className=" w-full h-auto mt-6 flex flex-col items-center text-[8px]">
                {/* <p> */}
                    <span className=" text-[8px]">Dine in - Take Away</span>
                    <span className=" italic text-[8px]"> Catering - Home Delivery </span>
                {/* </p> */}

            </div>
            <div className=" w-full h-auto mt-6 flex flex-col items-center text-[8px]">
                <span className=" font-bold text-[8px]">Powered By</span>
                {/* <p> */}
                    <span className=" text-[8px]">EasySheba Platform Limited</span>
                    <span className=" italic text-[8px]"> ( www.easysheba.com ) </span>
                {/* </p> */}

            </div>
        </div>
        
    );
}

export default Invoice;