
function Input1({onChange, type, name, placeholder, value, label, id, checked}){
    return(
        <div className=" w-full">
            <label htmlFor={id} className="block cursor-pointer mt-2 mb-1 text-gray-300">{label}</label>
            <input onChange={onChange} type={type} name={name} placeholder={placeholder} value={value} id={id} checked={checked} className=" w-full outline-none focus:bg-cyan-100 pl-2 py-1 rounded-sm text-gray-600 required:border-red-500 cursor-text" />
        </div>
    )
}

function Select({onChange, value, name, label, id, children, disable}){

    return(
        <div className=" w-full">
            <label htmlFor={id} className="block cursor-pointer mt-2 mb-1 text-gray-300">{label}</label>

            <select onChange={onChange} name={name} value={value} id={id} disabled={disable} className=" w-full outline-none focus:bg-cyan-100 pl-2 py-1 rounded-sm text-gray-600 required:border-red-500 cursor-pointer">
                    {children}         
            </select>

        </div>
    )
}


function Option({value, children}){
    return(
        <option className=" w-max h-max" value={value}>{children}</option>
    )
}

function CheckBox({onChange, name, value, label, id, checked}){
    return(
        <div className=" w-max flex items-center gap-4 py-2">
            <label htmlFor={id} className="block cursor-pointer mt-2 mb-1 text-gray-300">{label}</label>
            <input onChange={onChange} className=" w-5 h-5 cursor-pointer" type="checkbox" name={name} value={value} checked={checked} id={id} />
        </div>
    )

}

function Textarea({onChange, name, value, placeholder, label, id}){

    return(
        <>
            <label htmlFor={id} className="block cursor-pointer mt-2 mb-1 text-gray-300 ">{label}</label>
            <textarea className=" w-full outline-none focus:bg-cyan-100 pl-2 rounded-sm text-gray-600 required:border-red-500" onChange={onChange} id={id} name={name} value={value} placeholder={placeholder} cols="20" rows="6"></textarea>
        </>
    )
}

function Form1({title, children}){
    return(
        <form className=" relative w-11/12 sm:w-5/6 mx-auto mt-10 p-4 bg-dark-blue-1 pb-10 rounded-sm">
            <h1 className=" w-full text-center text-white text-xl font-bold pb-6">{title}</h1>
            {children}
        </form>
    )
}


export {Input1, Select, Option, Textarea, Form1, CheckBox}