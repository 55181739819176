const initialState = [] ;

function goodsCart(state = initialState, action){
    switch(action.type){
        case 'ADD_TO_CART_G' :

            const d = state.filter((data)=>{
                return( data.id === action.data.id);
            });

            if(d.length > 0 ){
                const s = state.map((data)=>{
                    if(data.id === action.data.id){
                        data.quantity =  action.data.quantity;
                        data.price =  action.data.price;
                    }
    
                    return(data);
    
                })
    
                state = s;
    
                return state;
            }else{
                
                state = [...state, action.data];
                
            }

            return state;
        

        case 'REMOVE_FROM_CART_G' :

            const dcc = state.filter((data)=>{
                return( data.id !== action.data.id);
            });

            state = [...dcc];

            return state;

        case 'CANCEL_ORDER_G' :
            state = [];

            return state;


        default : return state;
    }

}

export default goodsCart;