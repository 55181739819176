import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import url from '../../url';
import Loader from '../../components/Loader';

function GoodsDetails(){
    const [data, setData] = useState({useList:[]});
    const { id } = useParams();
    const [loader, setLoader] = useState(true);


    useEffect(()=>{

        fetch(`${url}/goods/${id}`, { mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
            setLoader(false)
            setData(data.data[0]);
        });
    },[id]);


    return(
        <>  
        {
            loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
            <div className=' w-full h-auto p-4'>
                <div className=" w-full h-[100px] bg-dark-blue-1 p-4 flex gap-x-4 justify-center items-center">
                    <div >
                        <span className=" text-white font-bold">Name: </span>
                        <span className=" text-white ">{data.name}</span>
                    </div>
                    <div >
                        <span className=" text-white font-bold">Total: </span>
                        <span className=" text-white ">{data.quantity + data.used} {data.unit}</span>
                    </div>
                    <div >
                        <span className=" text-white font-bold">Used: </span>
                        <span className=" text-white ">{data.used} {data.unit}</span>
                    </div>
                    <div >
                        <span className=" text-white font-bold">In Stock: </span>
                        <span className=" text-white ">{data.quantity} {data.unit}</span>
                    </div>
                </div>
                <table className=" w-full h-auto border border-slate-400">
                    <thead>
                        <tr className=" border-b border-slate-400">
                            <th className=" text-center border-r border-slate-400">#</th>
                            <th className=" text-center border-r border-slate-400">Previous Used</th>
                            <th className=" text-center border-r border-slate-400">Previous Stock</th>
                            <th className=" text-center border-r border-slate-400">Used Now</th>
                            <th className=" text-center border-r border-slate-400">Stock Now</th>
                            <th className=" text-center border-r border-slate-400">Used By</th>
                            <th className=" text-center border-r border-slate-400">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.useList.map((data, index)=>{
                                return(
                                    <tr key={index} className=" border-b border-slate-400">
                                        <td className=" text-center border-r border-slate-400">{index + 1}</td>
                                        <td className=" text-center border-r border-slate-400">{data.prevUse}</td>
                                        <td className=" text-center border-r border-slate-400">{data.prevStock}</td>
                                        <td className=" text-center border-r border-slate-400">{data.use}</td>
                                        <td className=" text-center border-r border-slate-400">{data.stock}</td>
                                        <td className=" text-center border-r border-slate-400">{data.useBy}</td>
                                        <td className=" text-center">{new Date(data.date).toDateString()}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div> 
        }
        </>
    )
}

export default GoodsDetails;