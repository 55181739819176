import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Input1, Option, Select, Textarea, Form1, CheckBox } from '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function EditFood() {
  const [value, setValue] = useState({name:'', description:'', price:0, vat:false, reword:false, categoryId:'', image:{}});
  const [category, setCategory] = useState([]);
  const [ img, setImg] = useState("");
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();
  const {id} = useParams();
   

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function setCheckBox(e){
    setValue({...value, [e.target.name]:e.target.checked});
  }

  function getImage(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }

  function send(){

    const formData = new FormData();

    formData.append('name',value.name);
    formData.append('description',value.description);
    formData.append('price',value.price);
    formData.append('vat',value.vat);
    formData.append('reword',value.reword);
    formData.append('categoryId',value.categoryId);
    formData.append('photo',value.image);

    fetch(`${url}/product/${id}`,{
        method:"PUT",
        body:  formData,
        credentials: 'include'
      }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate(-1);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
      });
    }

  useEffect(()=>{

    fetch(`${url}/category?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){;
            setCategory(data.result.data)
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });

    fetch(`${url}/product/${id}`,{
      method:"GET",
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          setValue({name: data.data[0].name, description: data.data[0].description, price: data.data[0].price, vat: data.data[0].vat, reword: data.data[0].reword,  categoryId:data.data[0].category._id});
          setImg(data.data[0].img);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });

  },[id]);



  return (
    <Form1 title="Edit Food">
          <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter food name:" value={value.name} label="Food name:" />
        <Textarea onChange={set} name="description" placeholder="Enter food description:" value={value.description} label="Food description:" />
        <Input1 onChange={set} type="number" name="price" placeholder="Enter food price:" value={value.price} label="Food price:" />
        <Select onChange={set}  name="categoryId" value={value.categoryId}  id="input4"  label="Select food category :">
        <Option value="" disable={true}>Select a food category</Option>
            {
                category.map((data, index)=>{
                    return(
                        <Option key={index} value={data._id}>{data.name}</Option>
                    )
                })
            }
            
        </Select>

        <div className=" flex gap-20">
          <CheckBox onChange={setCheckBox} name="vat" value={value.vat} checked={value.vat} id="vat" label="Vat/Tax: " />
          <CheckBox onChange={setCheckBox} name="reword" value={value.reword} checked={value.reword} id="reword" label="Reword Card: " />
        </div>
        <div className=' p-2 mt-8 rounded-sm'><img className=' w-20' src={img} alt="" /></div>
        <Input1 onChange={(e)=>getImage(e)} type="file" name="image" label="Upload an image." />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}


export default EditFood;