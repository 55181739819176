import React, { useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import url from '../../url';
import { ADD_TO_CART } from '../../store/actions/cart';
import { ADD_QUANTITY, REMOVE_QUANTITY, CANCEL_ORDER, EDIT_ORDER } from '../../store/actions/cart'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Alert1, Alert2, AlertContainer } from '../../components/Alert';

function Pos(){

    const [category, setCategory] = useState([]);
    const [product, setProduct] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [waiter, setWaiter] = useState([]);
    const [table, setTable] = useState([]);
    const [loader, setLoader] = useState(false);
    const [src, setSrc] = useState("");
    const [srcC, setSrcC] = useState("");
    const [cat, setCat] = useState("");
    const dispatch = useDispatch();
    const cart = useSelector((state)=> state.cart);
    const [totalPrice, setTotalPrice] = useState(0);
    const [vat, setVat] = useState(0);
    const [serviceCharge, setServiceCharge] = useState(0);
    const [value, setValue] = useState({customer:"", customerType:"Offline", waiter:"", table:""});
    const [alert, setAlert] = useState([]);
    const { id } = useParams();


    function set(e){
        setValue({...value, [e.target.name] : e.target.value});
    }

    function getBySearch(){

        setLoader(true);
        fetch(`${url}/product/search?search=${src}`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setSrc("");
            setCat(" ")
            setLoader(false);
            if(data.status === true){
                setProduct(data.result.data);
            }else{
                setProduct([]);
            }
        });
    }

    function getCustomerBySearch(){

        setLoader(true);
        fetch(`${url}/customer/search?search=${srcC}`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setSrcC("");
            setLoader(false);
            if(data.status === true){
                setCustomer(data.result.data);
            }else{
                setCustomer([]);
            }
        });
    }

    function getByCategory(id){
        setSrc("");
        setLoader(true);
        fetch(`${url}/product/search?search=${id}`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            if(data.status === true){
                setProduct(data.result.data);
            }else{
                setProduct([]);
            }
        });
    }

    useEffect(()=>{

        setLoader(true);
        fetch(`${url}/category`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setCategory(data.result.data);
        });

        fetch(`${url}/product`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setProduct(data.result.data);
        });

        fetch(`${url}/customer`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setCustomer(data.result.data);
        });

        fetch(`${url}/waiter`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setWaiter(data.result.data);
        });

        fetch(`${url}/table`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setTable(data.result.data);
        });

    },[]);

    useEffect(()=>{
        fetch(`${url}/sale/${id}`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);

            const {cart, totalPrice, vat, serviceCharge, customerType, customer, table, waiter  } = data.data[0]

            dispatch(EDIT_ORDER(cart));
            setTotalPrice(totalPrice);
            setVat(vat);
            setServiceCharge(serviceCharge);
            setValue({customer:customer._id, customerType, waiter, table, })
        });
    },[id, dispatch]);

    function inCart(id){
        const d = cart.filter((data)=>{
            return( data.id === id);
        });

        if(d.length > 0){
            return true;
        }else{
            return false;
        }
    }


    useEffect(()=>{
        if( cart.length !== 0){
            const price = cart.reduce((total, data)=>{
                if(data.vat){
                    return total + ((data.price * data.quantity) + ((data.price * data.quantity * vat)/100));
                }else{
                    return total + (data.price * data.quantity);
                }
                
            }, 0);
            
            setTotalPrice(price + ((price * serviceCharge)/100));
        }else{
            setTotalPrice(0);
        }
    },[cart, vat, serviceCharge]);

    function save(){

        if(value.customer === ""){
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="Please select a customer!" />]);
        }else{

            // if(cart.length === 0){
            //     setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="Cart is empty!" />]);
            // }else{
                fetch(`${url}/sale/pos/${id}`,{ 
                    method:"PUT",
                    credentials:"include",
                    mode:"cors",
                    body: JSON.stringify({...value, vat, serviceCharge, totalPrice, cart})
                }).then((data)=>data.json()).then((data)=>{

                    if(data.status === true){
                        setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Successful" message="Order placed successfully." />]);
    
                        dispatch(CANCEL_ORDER()); 
                        setVat(0); 
                        setServiceCharge(0);
                        setValue({customer:"", customerType:"Offline", waiter:"", table:""});

                        
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                    }

                })
            // };
        }

    }



    return(
        <>
            <section className=" hidden lg:block w-full h-[calc(100vh-152px)] select-none">
                {
                    loader?<Loader />:<></>
                }
                <div className= " absolute top-10 right-2">
                    <AlertContainer>
                        {alert}
                    </AlertContainer>
                </div>
                <div className=" w-[60%] h-[calc(100vh-152px)] p-2 float-left">
                    <div className=" w-full h-10 flex">
                        <input onChange={(e)=> setSrc(e.target.value)} className=' w-full p-1 pl-4 border-none outline-none shadow1 text-nav2 placeholder-nav2' type="text" name="search" id="search" value={src} placeholder="Search food..." />
                        <button onClick={getBySearch} className=" w-11 h-10 mx-2 flex items-center justify-center shadow3 text-nav2">
                            <i className="fa-solid fa-magnifying-glass text-xl"></i>
                        </button>
                    </div>
                    <div className=" w-[100%] flex mt-2">
                        <div className=" w-[150px] h-[calc(100vh-210px)] p-2 shrink-0 shadow1 overflow-y-auto">
                            <button onClick={()=>{getByCategory(" "); setCat("")}} className={` w-full mt-3 px-2 py-1 text-lg text-nav2 ${(cat === "")? "shadowActive": "shadow3" }`}>All Food</button>
                            {
                                category.map((data, index)=>{
                                    return(
                                        <button onClick={()=>{getByCategory(data._id); setCat(data._id)}} key={index} className={` w-full mt-3 px-2 py-1 text-lg text-nav2 ${(cat === data._id)? "shadowActive": "shadow3" }`}>{data.name}</button>
                                    )
                                })
                            }
                        </div>
                        <div className=" w-full h-[calc(100vh-210px)] ml-2 p-4 shadow1 overflow-y-auto flex flex-wrap gap-4 justify-evenly items-start">
                            {
                                product.map((data, index)=>{
                                    return(
                                        <div key={index}>
                                        {
                                            inCart(data._id)?
                                            <button onClick={()=>dispatch(ADD_TO_CART({id:data._id, name:data.name, price:data.price, vat:data.vat, quantity:1}))} className=" w-[150px] h-[170px] shadow1 rounded-[12px!important] p-1 flex flex-col items-center justify-center cursor-not-allowed">
                                                <div className=" w-[120px] h-[120px] bg-slate-300 bg-center bg-cover bg-no-repeat rounded-md" style={{ backgroundImage: `url(${data.img})`}}></div>
                                                <h1 className=" text-sm pt-1 text-nav2">{data.name}</h1>
                                            </button>
                                            :
                                            <button onClick={()=>dispatch(ADD_TO_CART({id:data._id, name:data.name, price:data.price, vat:data.vat, quantity:1}))} className=" w-[150px] h-[170px] shadow3 rounded-[12px!important] p-1 flex flex-col items-center justify-center cursor-pointer">
                                                <div className=" w-[120px] h-[120px] bg-slate-300 bg-center bg-cover bg-no-repeat rounded-md" style={{ backgroundImage: `url(${data.img})`}}></div>
                                                <h1 className=" text-sm pt-1 text-nav2">{data.name}</h1>
                                            </button>
                                        }
            
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className=" w-[40%] h-[calc(100vh-152px)] px-2 bg-slate-200 float-left">
                    <div className='w-full h-auto mt-2  flex flex-wrap gap-4'>
                        <div className=" w-[100%] flex grow ">
                            <div className=" w-[calc(100%-180px)] lg:w-[calc(100%-240px)] flex flex-col">
                                <label className=" text-nav2 cursor-pointer" htmlFor="customer">Customer Name</label>
                                <select onChange={(e)=>set(e)} className=" p-1 text-slate-500 shadow1 cursor-pointer border-none outline-none" name="customer" value={value.customer} id="customer">
                                <option value="" >Select customer</option>
                                {
                                    customer.map((data, index)=>{
                                        return(
                                            <option key={index} value={data._id}>{data.name}</option>
                                        );
                                    })
                                }
                                </select>
                            </div>
                            <div className=" w-[180px] lg:w-[240px] h-8 mt-5 ml-1 flex">
                                <input onChange={(e)=> setSrcC(e.target.value)} className=' w-[90px] lg:w-[120px] shrink-0 pl-4 border-none outline-none shadow1 text-nav2 placeholder-nav2' type="text" name="search" id="search" value={srcC} placeholder="Search..." />
                                <div className=" w-full flex">
                                    <button onClick={getCustomerBySearch} className=" w-9 h-8 ml-2 flex items-center justify-center shadow3 text-nav2  shrink-0">
                                        <i className="fa-solid fa-magnifying-glass text-lg"></i>
                                    </button>
                                    <Link to='/addCustomer' className=" w-9 h-8 ml-2 flex items-center justify-center shadow3 text-nav2  shrink-0">
                                    <i className="fa-solid fa-plus text-xl"></i>
                                    </Link>
                                </div>
                            </div>                        
                        </div>
                        <div className=" w-[160px] flex flex-col grow">
                            <label className=" text-nav2 cursor-pointer" htmlFor="customerType">Customer Type</label>
                            <select onChange={(e)=>set(e)} className=" p-1 text-slate-500 shadow1 cursor-pointer border-none outline-none" name="customerType" value={value.customerType} id="customerType">
                                <option value="Online">Online</option>
                                <option value="Offline">Offline</option>
                            </select>                         
                        </div>
                        {
                            (value.customerType !== "Online")?
                            <div className=" w-[160px] flex flex-col grow">
                                <label className=" text-nav2 cursor-pointer" htmlFor="waiter">Waiter</label>
                                <select onChange={(e)=>set(e)} className=" p-1 text-slate-500 shadow1 cursor-pointer border-none outline-none" name="waiter" value={value.waiter} id="waiter">
                                    <option value="">Select Waiter</option>
                                    {
                                        waiter.map((data, index)=>{
                                            return(
                                                <option key={index} value={data.name}>{data.name}</option>
                                            );
                                        })
                                    }
                                </select>                         
                            </div>:<></>
                        }
                        {
                            (value.customerType !== "Online")?
                            <div className=" w-[160px] flex flex-col grow">
                                <label className=" text-nav2 cursor-pointer" htmlFor="table">Table</label>
                                <select onChange={(e)=>set(e)} className=" p-1 text-slate-500 shadow1 cursor-pointer border-none outline-none" name="table" value={value.table} id="table">
                                    <option value="">Select Table</option>
                                    {
                                        table.map((data, index)=>{
                                            return(
                                                <option key={index} value={data.name}>{data.name}</option>
                                            );
                                        })
                                    }
                                </select>                         
                            </div>:<></>
                        }
                    </div>
                    <div className=' w-full max-h-96 overflow-y-auto p-2 mt-4 shadow1 bg-blue-500'>
                    {
                        (cart.length > 0)?
                        <table className=" w-full">
                            <thead>
                                <tr className=" border-b border-slate-300 text-nav2">
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Vat/Tax</th>
                                    <th>Quantity</th>
                                    <th>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cart.map((data, index)=>{
                                        return(
                                            <tr key={index} className=" border-b border-slate-300 text-slate-800">
                                                <td className=" text-center">{data.name}</td>
                                                <td className=" text-center">{data.price}৳</td>
                                                <td className=" text-center">{data.vat?vat:0}%</td>
                                                <td className=" flex items-center justify-center">
                                                    <div className=" w-[100px] my-4 shadow2 flex items-center justify-evenly">
                                                        <button onClick={()=>dispatch(REMOVE_QUANTITY({id:data.id}))} className=" text-red-700"><i className="fa-solid fa-minus"></i></button>
                                                        <span>{data.quantity}</span>
                                                        <button onClick={()=>dispatch(ADD_QUANTITY({id:data.id}))} className=" text-green-700"><i className="fa-solid fa-plus"></i></button>
                                                    </div>
                                                </td>
                                                <td className=" text-center">{data.vat?((data.price * data.quantity) +((data.price * data.quantity * vat)/100)):(data.price * data.quantity) }৳</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>:
                        <h1 className=" w-full text-center text-red-800">Cart is empty.</h1>
                    }
                    </div>
                </div>
            </section>
            <section className=" hidden  w-full h-[80px] bg-slate-200 lg:flex items-center justify-start select-none">
                <div className=" w-max flex flex-col gap-2 items-end px-4">
                    <div>
                        <label className=" cursor-pointer text-nav2" htmlFor="vat">Vat/Tax : </label>
                        <input onChange={(e)=>setVat(e.target.value)} className=" w-[100px] px-5 shadow1 border-none outline-none cursor-text" type="number" name="vat" value={vat} id="vat"></input>
                    </div>
                    <div>
                        <label className=" cursor-pointer text-nav2" htmlFor="serviceCharge">Service Charge : </label>
                        <input onChange={(e)=>setServiceCharge(e.target.value)} className=" w-[100px] px-5 shadow1 border-none outline-none cursor-text" type="number" name="serviceCharge" value={serviceCharge} id="serviceCharge" ></input>
                    </div>
                </div>
                <div className=" w-max flex gap-4">
                    <div className=" text-xl">
                        <span className=" font-bold">Total Price: </span>
                        <span>{totalPrice.toFixed(2)}৳</span>
                    </div>
                    <div>
                        <button onClick={()=>{ dispatch(CANCEL_ORDER()); setVat(0); setServiceCharge(0); setValue({customer:"", customerType:"Offline", waiter:"", table:""})}} className=" mr-2 p-1 px-2 bg-red-700 hover:bg-red-900 text-white rounded-md">Cancel Order</button>
                        <button onClick={save} className=" p-1 px-2 bg-nav2 hover:bg-nav2hover text-white rounded-md">Place Order</button>
                    </div>
                </div>
            </section>
            <div className=" lg:hidden w-full mt-20 text-lg text-center font-bold text-red-800">This feature is only available for large device!</div>
        </>
    )
}

export default Pos;