import { combineReducers } from 'redux';
import page from './page';
import limit from './limit';
import cart from './cart'
import goodsCart from './goodsCart'


const rootReducer = combineReducers({
    limit,
    page,
    cart,
    goodsCart

});

export default rootReducer;