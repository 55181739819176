import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Edit, UseGoods, View } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url';

function AllGoods() {

const [goods, setGoods] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);



function delet(id){
    fetch(`${url}/goods/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/goods?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setGoods(data.result);
                    }
                });
            }else{
                setLoader(true);
                fetch(`${url}/goods/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setGoods(data.result);
                    }
                });
                 
            }
        }else{

        }
    });
}
useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/goods?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setGoods(data.result);
            }
        });
    }

    return () => setGoods({data:[],next:{},previous:{}});

},[page,src, limit]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/goods/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setGoods(data.result);
            }
        });
    
    }

    return () => setGoods({data:[],next:{},previous:{}});
},[page, limit, src]);

function search(e){
    setSrc(e.target.value);
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="/addGoods" name="Add Goods" rowNames={["#","Name","Price", "Total","Used", "In Stock", "Actions"]} srcVal={src} srcFunc={search} data={goods} width='w-full'>
                {
                    goods.data.map(({_id, name, price, unit, used, quantity }, index)=>{
                        return(
                            <Tr key={index}>
                                <Td>{((goods.previous.page*goods.previous.limit)+1)+index}</Td>
                                <Td>{name}</Td>
                                <Td>{price}</Td>
                                <Td>{quantity + used} {unit}</Td>
                                <Td>{used} {unit}</Td>
                                <Td>{quantity } {unit}</Td>
                                <Td>
                                    <UseGoods to={"/useGoods/"+_id} />
                                    <View to={"/goods/"+_id} />
                                    <Edit to={"/editGoods/"+_id} />
                                    <Delete id={_id} click={delet} />
                                </Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
}</>)
}

export default AllGoods;