import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url'

function AllReword() {

const [reword, setReword] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);



function delet(id){
    fetch(`${url}/reword/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/reword?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setReword(data.result);
                    }
                });
            }else{
                setLoader(true);
                fetch(`${url}/reword/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
                    setLoader(false);
                    if(data.status === true){
                        setReword(data.result);
                    }
                });
                 
            }
        }else{

        }
    });
}
useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/reword?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setReword(data.result);
            }
        });
    }

    return () => setReword({data:[],next:{},previous:{}});

},[page,src, limit]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/reword/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setReword(data.result);
            }
        });
    
    }

    return () => setReword({data:[],next:{},previous:{}});
},[page, limit, src]);


function search(e){
    setSrc(e.target.value);
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="/addReward" name="Add Reward" rowNames={["#","Name","Description","Discount","Category","Actions"]} srcFunc={search} data={reword} width='w-full'>
                {
                    reword.data.map(({_id, name, description,  discount, category}, index)=>{
                        return(
                            <Tr key={index}>
                                <Td>{((reword.previous.page*reword.previous.limit)+1)+index}</Td>
                                <Td>{name}</Td>
                                <Td width='w-40'>{description}</Td>
                                <Td width='w-40'>{discount}</Td>
                                <Td width='w-40'>
                                    {
                                        category.map((data, index)=>{
                                            return(<span key={index}>{data.name}, </span>);
                                        })
                                    }
                                </Td>
                                <Td>
                                    {/* <Edit to={"/editreword/"+_id} /> */}
                                    <Delete id={_id} click={delet} />
                                </Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
}</>)
}

export default AllReword;