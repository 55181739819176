import { Route, Routes } from "react-router-dom";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import Home from "./pages/Home";
import LogIn from "./pages/Login";
import Pos from "./pages/Pos";

import IsLogIn from "./components/IsLogIn";
import IsLogOut from "./components/IsLogOut";

import AllUser from "./pages/user/AllUser";
import AddUser from "./pages/user/AddUser";

import Profile from "./pages/Profile";
import Setting from "./pages/Setting";

import AllCategory from "./pages/category/AllCategory";
import AddCategory from "./pages/category/AddCategory";
import EditCategory from "./pages/category/EditCategory";

import AllFood from "./pages/food/AllFood";
import AddFood from "./pages/food/AddFood";
import EditFood from "./pages/food/EditFood";

import AllExpenseCategory from "./pages/expenseCategory/AllExpenseCategory";
import AddExpenseCategory from "./pages/expenseCategory/AddExpenseCategory";

import AllExpense from "./pages/expense/AllExpense";
import AddExpense from "./pages/expense/AddExpense";

import AddAccessories from "./pages/accessories/AddAccessories";
import EditAccessories from "./pages/accessories/EditAccessories";
import AllAccessories from "./pages/accessories/AllAccessories";


import AllSupplier from "./pages/supplier/AllSupplier";
import AddSupplier from "./pages/supplier/AddSupplier";
import EditSupplier from "./pages/supplier/EditSupplier";


import AllUnit from "./pages/unit/AllUnit";
import AddUnit from "./pages/unit/AddUnit";
import EditUnit from "./pages/unit/EditUnit";


import AddGoods from "./pages/goods/AddGoods";
import AllGoods from "./pages/goods/AllGoods";
import EditGoods from "./pages/goods/EditGoods";
import UseGoods from "./pages/goods/UseGoods";
import GoodDetails from "./pages/goods/GoodDetails";
import Purchase from "./pages/goods/Purchase";
import PurchaseGoods from "./pages/goods/PurchaseGoods";
import PurchaseInvoice from "./pages/goods/PurchaseInvoice";


import AllCustomer from "./pages/customer/AllCustomer";
import AddCustomer from "./pages/customer/AddCustomer";
import EditCustomer from "./pages/customer/EditCustomer";

import AllWaiter from "./pages/waiter/AllWaiter";
import AddWaiter from "./pages/waiter/AddWaiter";
import EditWaiter from "./pages/waiter/EditWaiter";

import AllTable from "./pages/table/AllTable";
import AddTable from "./pages/table/AddTable";
import EditTable from "./pages/table/EditTable";

import AddCoupon from "./pages/coupon/AddCoupon";
import AllCoupon from "./pages/coupon/AllCoupon";

import AddReword from "./pages/reword/AddReword";
import AllReword from "./pages/reword/AllReword";

import Counter from "./pages/order/Counter";

import AddBooking from "./pages/booking/AddBooking";
import AllBooking from "./pages/booking/AllBooking";
import SaleBooking from "./pages/booking/SaleBooking";

import AllOrder from "./pages/order/AllOrder";
import NewOrder from "./pages/order/NewOrder";
import Kitchen from "./pages/order/Kitchen";
import CompleteOrder from "./pages/order/CompleteOrder";
import CancelOrder from "./pages/order/CancelOrder";

import TodaySale from "./pages/sale/TodaySale";
import ThisWeekSale from "./pages/sale/ThisWeekSale";
import ThisMonthSale from "./pages/sale/ThisMonthSale";
import ThisYearSale from "./pages/sale/ThisYearSale";

import TodayExpense from "./pages/expenseReport/TodayExpense";
import ThisWeekExpense from "./pages/expenseReport/ThisWeekExpense";
import ThisMonthExpense from "./pages/expenseReport/ThisMonthExpense";
import ThisYearExpense from "./pages/expenseReport/ThisYearExpense";


import UpdateOrder from "./pages/order/UpdateOrder";
import UpdatePos from "./pages/order/UpdatePos";
import ViewOrder from "./pages/order/ViewOrder";

import Invoice from "./pages/order/Invoice";
import InvoicePhone from "./pages/order/InvoicePhone";
import PrintToken from "./pages/order/PrintToken";


import Layout from "./components/Layout";
import { NavLInkHome } from "./components/NavLink";
import Dashboard from "./pages/Dashboard";
import CheckUser from "./components/CheckUser";

function App() {
  return (
    <>
      <Routes>
        <Route path="/*" element={<IsLogOut />}>
          <Route path="logIn" element={<LogIn />} />
        </Route>

        <Route path="/" element={<IsLogIn />}>
          <Route path="/" element={<Home />} />
          <Route path="order/invoice/:id" element={<Invoice />} />
          <Route path="order/invoicePhone/:id" element={<InvoicePhone />} />
          <Route path="purchase/invoice/:id" element={<PurchaseInvoice />} />
          <Route path="token/:id" element={<PrintToken />} />

          <Route path="/" element={<CheckUser status="both" />}>
            <Route path="/*" element={<Layout nav={<NavLInkHome />} />}>

              <Route path="profile" element={<Profile />} />
              <Route path="setting" element={<Setting />} />

              <Route path="pos" element={<Pos />} />

              <Route path="addBooking" element={<AddBooking />} />
              <Route path="allBooking" element={<AllBooking />} />
              <Route path="booking/sale/:id" element={<SaleBooking />} />

              <Route path="allOrder" element={<AllOrder />} />
              <Route path="newOrder" element={<NewOrder />} />
              <Route path="kitchen" element={<Kitchen />} />
              <Route path="completeOrder" element={<CompleteOrder />} />
              <Route path="cancelOrder" element={<CancelOrder />} />

              <Route path="todaySale" element={<TodaySale />} />
              <Route path="thisWeekSale" element={<ThisWeekSale />} />
              <Route path="thisMonthSale" element={<ThisMonthSale />} />
              <Route path="thisYearSale" element={<ThisYearSale />} />

              <Route path="todayExpense" element={<TodayExpense />} />
              <Route path="thisWeekExpense" element={<ThisWeekExpense />} />
              <Route path="thisMonthExpense" element={<ThisMonthExpense />} />
              <Route path="thisYearExpense" element={<ThisYearExpense />} />

              <Route path="counter" element={<Counter />} />

              <Route path="order/:id" element={<UpdateOrder />} />
              <Route path="pos/update/:id" element={<UpdatePos />} />
              <Route path="order/view/:id" element={<ViewOrder />} />

              <Route path="AddCustomer" element={<AddCustomer />} />
            </Route>
          </Route>

          <Route path="/*" element={<CheckUser status="admin" />}>
            <Route path="/*" element={<Layout nav={<NavLInkHome />} />}>
              <Route path="dashboard" element={<Dashboard />} />

              <Route path="user" element={<AllUser />} />
              <Route path="addUser" element={<AddUser />} />

              <Route path="category" element={<AllCategory />} />
              <Route path="AddCategory" element={<AddCategory />} />
              <Route path="editCategory/:id" element={<EditCategory />} />

              <Route path="food" element={<AllFood />} />
              <Route path="AddFood" element={<AddFood />} />
              <Route path="editFood/:id" element={<EditFood />} />

              <Route path="expenseCategory" element={<AllExpenseCategory />} />
              <Route path="AddExpenseCategory" element={<AddExpenseCategory />} />
              {/* <Route path="editExpenseCategory/:id" element={<EditExpenseCategory />} /> */}

              <Route path="accessories" element={<AllAccessories />} />
              <Route path="addAccessories" element={<AddAccessories />} />
              <Route path="editAccessories/:id" element={<EditAccessories />} />

              <Route path="expense" element={<AllExpense />} />
              <Route path="addExpense" element={<AddExpense />} />


              <Route path="suppliers" element={<AllSupplier />} />
              <Route path="AddSupplier" element={<AddSupplier />} />
              <Route path="editSupplier/:id" element={<EditSupplier />} />

              <Route path="Unit" element={<AllUnit />} />
              <Route path="AddUnit" element={<AddUnit />} />
              <Route path="editUnit/:id" element={<EditUnit />} />

              <Route path="goods" element={<AllGoods />} />
              <Route path="addGoods" element={<AddGoods />} />
              <Route path="editGoods/:id" element={<EditGoods />} />
              <Route path="allGoods" element={<AllGoods />} />
              <Route path="goods/:id" element={<GoodDetails />} />
              <Route path="useGoods/:id" element={<UseGoods />} />
              <Route path="purchase" element={<Purchase />} />
              <Route path="purchaseGoods" element={<PurchaseGoods />} />

              <Route path="customer" element={<AllCustomer />} />
              <Route path="AddCustomer" element={<AddCustomer />} />
              <Route path="editCustomer/:id" element={<EditCustomer />} />

              <Route path="waiter" element={<AllWaiter />} />
              <Route path="AddWaiter" element={<AddWaiter />} />
              <Route path="editWaiter/:id" element={<EditWaiter />} />

              <Route path="table" element={<AllTable />} />
              <Route path="AddTable" element={<AddTable />} />
              <Route path="editTable/:id" element={<EditTable />} />

              <Route path="coupon" element={<AllCoupon />} />
              <Route path="AddCoupon" element={<AddCoupon />} />

              <Route path="reward" element={<AllReword />} />
              <Route path="AddReward" element={<AddReword />} />

              <Route path="newOrder" element={<NewOrder />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
