import React, { useState, useEffect } from 'react';
import { Button1 } from '../../components/Button';
import { Form1, Input1, Option, Select } from '../../components/Input';
import url from '../../url';
import Loader from '../../components/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert2, AlertContainer } from '../../components/Alert';

function EditGoods (){
    const [value, setValue] = useState({name:'', price:'', unit:''});
    const [unit, setUnit] = useState([]);
    const navigate = useNavigate();
    const [alert, setAlert] = useState([]);
    const [loader, setLoader] = useState(false);
    
    const { id } = useParams();
    
    function set(e){
        setValue({...value, [e.target.name]:e.target.value});
    }

    useEffect(()=>{
        fetch(`${url}/unit`,{mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setUnit(data.result.data);
            }
        });

        setLoader(true)
        fetch(`${url}/goods`,{mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false)
            if(data.status === true){
                setValue(data.result.data[0]);
            }
        });


    },[]);



    function send(){
        if(value.name !== '' && value.price !== '' &&  value.unit !== ''){
            fetch(`${url}/goods/${id}`,{
                method:"PUT",
                body: JSON.stringify(value),
                credentials: 'include'
            }).then((data)=>data.json()).then((data)=>{
                if(data.status === true){
                  navigate(-1);
                }
            });
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="All  fields are required!" />]);
        }

    }
    

    
    return(
        <Form1 title="Add Goods">
            {
                loader?<Loader />:<></>
            }
            <div className= " absolute top-10 right-2">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            <Input1 onChange={set} type="text" name="name" value={value.name}  placeholder="Enter goods name:" label="Goods name:" id="name" />
            <Input1 onChange={set} type="number" name="price" value={value.price} placeholder="Enter goods price:" label="Goods price:" id="price" />
            <Select onChange={set} name="unit" value={value.unit} label="Select goods unit:" id="unit">
                <Option value="">Select unit</Option>
                {
                    unit.map(({shortName}, index)=>{

                        return(<Option key={index} value={shortName}>{shortName}</Option>)
                    })
                }
            </Select>
            <Button1 click={send} name="Send" />
        </Form1>
    )
}

export default EditGoods;