import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import url from '../url';
import useAuth from '../hooks/useAuth';

function Nav({toggleMin, min, nav}){
    const [style, setStyle] = useState('hidden');
    const navigate = useNavigate();
     const { name, img, role } = useAuth();
    
    /* eslint-disable-next-line */
    const [cookies, setCookie, removeCookie] = useCookies(['auth']);

    function logOut(){

        removeCookie('auth',[{expires: Date.now()}]);
    
        fetch(`${url}/user/logout`,{method:'DELETE',mode:'cors',credentials:'include'}).then((data)=>data.json()).then((data)=>{
            navigate('/logIn');
        });
    
      }

      

    return(
        <nav className={` w-[calc(100%-30px)] ${min?'md:w-[calc(100%-70px)]':'md:w-[calc(100%-300px)]'} h-[70px] flex items-center justify-center bg-nav1 shadow-md float-left select-none`}>
            <div className=" hidden  w-max h-[70px] px-2 md:flex items-center justify-center">
                <button onClick={()=>toggleMin()} className=' w-max h-max'>
                    <i className="fa-solid fa-bars text-4xl"></i>
                </button>
            </div>
            <div className=" w-full h-[70px] shrink flex items-center justify-center gap-2 md:gap-4">
                {nav}
            </div>
            <div className=" w-[80px] h-[70px] flex items-center justify-center ">
                <div onMouseEnter={()=>setStyle('block')} onMouseLeave={()=>setStyle('hidden')} className=" w-[60px] h-[60px] rounded-full bg-center bg-cover bg-no-repeat cursor-pointer select-none relative" style={{ backgroundImage: `url('${img}'`}} >
                    <div className=" w-[150px] h-5 mt-[60px] absolute top-0 right-0"></div>

                    <div className={`${style} w-[250px] h-[250px] p-2 mt-[80px] absolute top-0 right-0 bg-sidebar1 `}>
                        <div className=' w-8 h-8 rotate-45 absolute -mt-2 top-0 right-3 bg-sidebar1'></div>
                        <div className=" text-white">
                            <h1 className=' text-lg font-bold'>{name}</h1>
                            <h2 className=" text-main">({role})</h2>
                            <hr className=" mt-2" />
                            <div className=" mt-5">
                                <Link to={'/profile'} className=" w-full py-1 px-3 rounded-md text-lg font-bold bg-sky-500 hover:bg-sky-600 flex items-center gap-3" ><i className="fa-solid fa-id-card text-3xl"></i> <span>Profile</span></Link>
                                <Link to='/setting' className=" w-full mt-2 py-1 px-3 rounded-md text-lg font-bold bg-amber-500 hover:bg-amber-700 flex items-center gap-3" ><i className="fa-solid fa-user-pen text-3xl"></i> <span>Update</span></Link>
                                <button onClick={logOut} className=" w-full mt-2 py-1 px-3 rounded-md text-lg font-bold bg-rose-600 hover:bg-rose-800 flex items-center gap-3" ><i className="fa-solid fa-power-off text-3xl"></i> <span>LogOut</span></button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </nav>
    );
}

export default Nav;