import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Food } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url'
import { Alert2, AlertContainer } from '../../components/Alert';

function AllBooking() {

const [booking, setBooking] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);
const [alert, setAlert] = useState([]);



function delet(id){
    fetch(`${url}/booking/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/booking?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                    setLoader(false);
                    if(data.status === true){
                      setBooking(data.result);
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                    }
                });
            }
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });
}


    useEffect(()=>{
        if(src === ''){
            setLoader(true);
            fetch(`${url}/booking?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                setLoader(false);

                if(data.status === true){;
                    setBooking(data.result);
                }else{
                    setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                }
            });
        }

        return () => setBooking({data:[],next:{},previous:{}});

    },[page, src, limit]);




// useEffect(()=>{


//     if(src !== ''){
//         fetch(`${url}/booking/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
//             if(data.status === true){
//                 setBooking(data.result);
//             }else{
//                 setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
//             }
//         });
    
//     }

//     return () => setBooking({data:[],next:{},previous:{}});
// },[page, limit, src]);


// useEffect(()=>{
//     setLoader(true);
//     fetch(`${url}/expenseCategory?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
//         setLoader(false);
//         if(data.status === true){
//             setCategory(data.result.data);
//         }else{
//             setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
//         }
//     });

//   },[]);

function search(e){
    setSrc(e.target.value);
}


// useEffect(()=>{

//     setLoader(true);
//     fetch(`${url}/booking/search?page=${page}&limit=${limit}&search=${select}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
//         setLoader(false);
//         if(data.status === true){
//             setBooking(data.result);
//         }else{
//             setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
//             setBooking({data:[],next:{},previous:{}})
//         }
//     });
// },[select, limit, page]);


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
      <div className=' w-max md:w-auto mx-auto'>

        <div className= " fixed top-32 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Table to="/addBooking" name="Add Booking" rowNames={["#","Customer Name","Customer Email", "Customer Phone", "Table", "Booking Time","Added At","Actions"]} srcVal={src} srcFunc={search} data={booking} width='w-full'>
            {
                booking.data.map(({_id, customer, table, time , date}, index)=>{
                    const d = new Date();
                    d.setTime(Number(time));
                    return(
                        <Tr key={index}>
                            <Td>{((booking.previous.page*booking.previous.limit)+1)+index}</Td>
                            <Td>{customer.name}</Td>
                            <Td>{customer.email}</Td>
                            <Td>{customer.phone}</Td>
                            <Td >{table.name}</Td>
                            <Td >{d.toLocaleString()}</Td>
                            <Td>{ new Date(date).toLocaleString()}</Td>
                            <Td>
                                {/* <Edit to={"/editExpense/"+_id} /> */}
                                <Delete id={_id} click={delet} />
                                <Food to={'/booking/sale/'+_id}/>
                            </Td>
                        </Tr>
                    );
                })
            }
                    
        </Table>
    </div>
}</>)
}
export default AllBooking;