function ADD_TO_CART(data){
    return({type:"ADD_TO_CART",data});
}

function REMOVE_FORM_CART(data){
    return({type:"REMOVE_FORM_CART",data});
}

function ADD_QUANTITY(data){
    return({type:"ADD_QUANTITY",data});
}

function REMOVE_QUANTITY(data){
    return({type:"REMOVE_QUANTITY",data});
}

function CANCEL_ORDER(){
    return({type:"CANCEL_ORDER"});
}

function EDIT_ORDER(data){
    return({type:"EDIT_ORDER", data});
}



export { ADD_TO_CART, REMOVE_FORM_CART, ADD_QUANTITY, REMOVE_QUANTITY, CANCEL_ORDER, EDIT_ORDER };