function ADD_TO_CART_G(data){
    return({type:"ADD_TO_CART_G",data});
}

function REMOVE_FROM_CART_G(data){
    return({type:"REMOVE_FROM_CART_G",data});
}


function CANCEL_ORDER_G(){
    return({type:"CANCEL_ORDER_G"});
}




export { ADD_TO_CART_G, REMOVE_FROM_CART_G, CANCEL_ORDER_G};