import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Counter, Edit, Invoice, View } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url';

function ThisWeekSale() {

const [sale, setSale] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);
const [total, setTotal] = useState({TTotalPrice:0, TPayable:0, TDue:0, TPaid:0});



useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/report/thisWeekSale?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setSale(data.result);
                let TTotalPrice = 0, TPayable = 0, TDue = 0, TPaid = 0;
                data.result.data.map((data)=>{
                    TTotalPrice = TTotalPrice + data.totalPrice;
                    TPayable = TPayable + (data.totalPrice - data.discount);
                    TDue = TDue + (data.due - data.discount - data.payed);
                    TPaid = TPaid + data.payed;
                    return(0);
                });
                setTotal({TTotalPrice, TPayable, TDue, TPaid});
            }
        });
    }

    return () => setSale({data:[],next:{},previous:{}});

},[page,src, limit]);

// useEffect(()=>{
//     if(src !== ''){
//         fetch(`${url}/sale/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
//             if(data.status === true){
//                 setSale(data.result);
//             }
//         });
    
//     }

//     return () => setSale({data:[],next:{},previous:{}});
// },[page, limit, src]);

function search(e){
    setSrc(e.target.value);
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="" name="" rowNames={["#","Customer Name","Customer email","Customer phone","Customer Type", "Waiter", "Table","Total Price", "Vat/Tax", "Service Charge","Coupon", "Reword", "Discount", "Payable", "Paid", "Due","Status","Date","Actions"]} srcVal={src} srcFunc={search} data={sale} width='w-full'>
                {
                    sale.data.map(({_id, totalPrice, vat, serviceCharge, coupon, reword, discount, payed, due, customer, customerType, waiter, table, status, customerName, customerEmail, customerPhone, date}, index)=>{
                        const d = new Date(date);
                        return(
                            <Tr key={index}>
                                <Td>{((sale.previous.page*sale.previous.limit)+1)+index}</Td>
                                <Td>{customerName}</Td>
                                <Td>{customerEmail}</Td>
                                <Td>{customerPhone}</Td>
                                <Td>{customerType}</Td>
                                <Td>{waiter}</Td>
                                <Td>{table}</Td>
                                <Td>{totalPrice}</Td>
                                <Td>{vat}</Td>
                                <Td>{serviceCharge}</Td>
                                <Td>{coupon}</Td>
                                <Td>{reword}</Td>
                                <Td>{discount}</Td>
                                <Td>{totalPrice - discount}</Td>
                                <Td>{payed}</Td>
                                <Td>{due - discount - payed}</Td>
                                <Td>
                                {
                                        (status === "pending")? <span className=" bg-yellow-500 text-white text-sm px-2 pb-1 rounded-full">Pending</span>:<></>
                                    }
                                    {
                                        (status === "cooking")? <span className=" bg-orange-600 text-white text-sm px-2 pb-1 rounded-full">Cooking</span>:<></>
                                    }
                                    {
                                        (status === "served")? <span className=" bg-green-600 text-white text-sm px-2 pb-1 rounded-full">Served</span>:<></>
                                    }
                                    {
                                        (status === "Paid")? <span className=" bg-blue-600 text-white text-sm px-2 pb-1 rounded-full">Paid</span>:<></>
                                    }
                                    {
                                        (status === "cancel")? <span className=" bg-red-600 text-white text-sm px-2 pb-1 rounded-full">Cancel</span>:<></>
                                    }
                                    {
                                        (status === "complete")? <span className=" bg-blue-900 text-white text-sm px-2 pb-1 rounded-full">Completed</span>:<></>
                                    }
                                </Td>

                                <Td>{d.toLocaleString()}</Td>

                                <Td>
                                    <View to={"/order/view/"+_id} />
                                    <Edit to={"/pos/update/"+_id} />
                                    <Counter to={"/order/"+_id} />
                                    {
                                        (status === "Paid" || status === "complete")? <Invoice to={"/order/invoice/"+_id} />:<></>
                                    }
                                </Td>
                            </Tr>
                        );
                    })
                }
                <tr className=" bg-slate-400 text-black">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td className="  font-bold text-center">Total</td>
        <td></td>
        <td></td>
        <td className=" border-x border-black text-center font-bold">{total.TPayable}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td className=" border-x border-black text-center font-bold">{total.TPayable}</td>
        <td className=" border-x border-black text-center font-bold">{total.TPaid}</td>
        <td className=" border-x border-black text-center font-bold">{total.TDue}</td>
        <td></td>
        <td></td>
    </tr>
                
            </Table>
}</>)
}

export default ThisWeekSale;