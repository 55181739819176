import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Delete, Edit } from '../../components/Button';
import Filter from '../../components/Filter';
import { Option, Select } from '../../components/Input';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url'
import { Alert2, AlertContainer } from '../../components/Alert';

function AllFood() {

const [food, setProduct] = useState({data:[],next:{},previous:{}});
const [category, setCategory] = useState([]);
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);
const [alert, setAlert] = useState([]);
const [select, setSelect] = useState("");



function delet(id){
    fetch(`${url}/product/${id}`,{method:'DELETE',mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
            if(src === ''){
                setLoader(true);
                fetch(`${url}/product?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                    setLoader(false);
                    if(data.status === true){
                      setProduct(data.result);
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                    }
                });
            }else{
                setLoader(true);
                fetch(`${url}/product/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                    setLoader(false);
                    if(data.status === true){
                      setProduct(data.result);
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                    }
                });
                 
            }
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });
}
useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/product?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            if(data.status === true){;
                setProduct(data.result);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
            }
        });
    }

    return () => setProduct({data:[],next:{},previous:{}});

},[page, src, limit]);

useEffect(()=>{


    if(src !== ''){
        fetch(`${url}/product/search?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setProduct(data.result);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
            }
        });
    
    }

    return () => setProduct({data:[],next:{},previous:{}});
},[page, limit, src]);


useEffect(()=>{
    setLoader(true);
    fetch(`${url}/category?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        setLoader(false);
        if(data.status === true){
            setCategory(data.result.data);
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });

  },[]);

function search(e){
    setSrc(e.target.value);
}


useEffect(()=>{

    setLoader(true);
    fetch(`${url}/product/search?page=${page}&limit=${limit}&search=${select}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        setLoader(false);
        if(data.status === true){
            setProduct(data.result);
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
            setProduct({data:[],next:{},previous:{}})
        }
    });
},[select, limit, page]);



  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
      <div className=' w-max md:w-auto mx-auto'>
        <Filter >
            <div className=' w-80 '>
                <Select onChange={(e)=>{ setSelect(e.target.value) }} value={select}  name="categoryId"  id="input4"  label="Select Category :">
                <Option value="" disable={true}>Select a category</Option>
                <Option value="" >All Category</Option>
                {
                    category.map((data, index)=>{
                        return(
                            <Option key={index} value={data._id}>{data.name}</Option>
                        )
                    })
                }
                </Select>
            </div>

        </Filter>
        <div className= " fixed top-32 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Table to="/addFood" name="Add Food" rowNames={["#","Name","Description", "Price","Vat/Tax","Reword","Category","Image","Actions"]} srcVal={src} srcFunc={search} data={food} width='w-full'>
                    {
                        food.data.map(({_id, name, description, price, vat, reword, category, img}, index)=>{
                            return(
                                <Tr key={index}>
                                    <Td>{((food.previous.page*food.previous.limit)+1)+index}</Td>
                                    <Td>{name}</Td>
                                    <Td width='w-40'>{description}</Td>
                                    <Td >{price} ৳</Td>
                                    <Td >{vat?"Yes":"NO"} </Td>
                                    <Td >{reword?"Yes":"NO"} </Td>
                                    <Td>{category.name}</Td>
                                    <Td width='w-40 '><img className=' w-20 text-center block mx-auto' src={img} alt="" /></Td>
                                    <Td>
                                        <Edit to={"/editFood/"+_id} />
                                        <Delete id={_id} click={delet} />
                                    </Td>
                                </Tr>
                            );
                        })
                    }
                    
        </Table>
    </div>
}</>)
}
export default AllFood;