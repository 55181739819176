import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Input1, Option, Select, Textarea, Form1, CheckBox } from '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';

function AddFood() {
  const [value, setValue] = useState({name:'', description:'', price:0, categoryId:'', vat:false, reword:false, image:{}});
  const [category, setCategory] = useState([]);
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();


  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function setCheckBox(e){
    setValue({...value, [e.target.name]:e.target.checked});
  }

  function getImage(e){
    setValue({...value,[e.target.name]:e.target.files[0]});
  }

  function send(){
    
    const formData = new FormData();

    formData.append('name',value.name);
    formData.append('description',value.description);
    formData.append('price',value.price);
    formData.append('vat',value.vat);
    formData.append('reword',value.reword);
    formData.append('categoryId',value.categoryId);
    formData.append('photo',value.image);


      fetch(`${url}/product`,{
        method:"POST",
          body:  formData,
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate(-1);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
      });

    }

  useEffect(()=>{

    fetch(`${url}/category?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){;
          setCategory(data.result.data)
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });

  },[]);


  return (
    <Form1 title="Add Food">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="name" placeholder="Enter service name:" value={value.name} label="Food name:" />
        <Textarea onChange={set} name="description" placeholder="Enter food description:" value={value.description} label="Food description:" />
        <Input1 onChange={set} type="number" name="price" placeholder="Enter service price:" value={value.price} label="Food price:" />
        <Select onChange={set}  name="categoryId" value={value.categoryId}  id="input4"  label="Select category :">
          <Option value="" disable={true}>Select a category</Option>
          {
              category.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>

        <div className=" flex gap-20">
          <CheckBox onChange={setCheckBox} name="vat" value={value.vat} checked={value.vat} id="vat" label="Vat/Tax: " />
          <CheckBox onChange={setCheckBox} name="reword" value={value.reword} checked={value.reword} id="reword" label="Reword Card: " />
        </div>

        <Input1 onChange={(e)=>getImage(e)} type="file" name="image" label="Upload an image." />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddFood;