import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Input1, Option, Select, Form1 } from '../../components/Input';
import url from '../../url';
import { Alert1, Alert2, AlertContainer } from '../../components/Alert';
import Loader from '../../components/Loader';

function UpdateOrder() {
    const [data, setData] = useState({ due:0, payed: 0});
    const [value, setValue] = useState({ payed:0, status:""});
    const [coupon, setCoupon] = useState("");
    const [reword, setReword] = useState("");
    const [discount, setDiscount] = useState(0);
    const [get, setGet] = useState(0);
    const [alert, setAlert] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const {id} = useParams();
   

    function set(e){
    setValue({...value, [e.target.name]:e.target.value});
    }


    function send(){

        fetch(`${url}/sale/${id}`,{
            method:"PUT",
            body:  JSON.stringify({...value, "prevPaid":data.payed}),
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                navigate(-1);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
            }
        });
    }

    function checkCoupon(){
        setLoader(true);
        fetch(`${url}/coupon/check`,{
            method:"POST",
            body:  JSON.stringify({cart:data.cart, coupon}),
            credentials: 'include'
        }).then((data)=>data.json()).then((cp)=>{
            setLoader(false);
            if(cp.status === true){
                setReword("")
                setLoader(true);
                if(cp.discountType === "TK"){
                    fetch(`${url}/sale/${id}`,{
                        method:"PUT",
                        body:  JSON.stringify({coupon, discount: cp.discount, "payed":0, "prevPaid":0, reword:""}),
                        credentials: 'include'
                    }).then((data)=>data.json()).then((data)=>{
                        if(data.status === true){
                            fetch(`${url}/sale/${id}`,{
                                method:"GET",
                                credentials: 'include'
                              }).then((data)=>data.json()).then((data)=>{
                                  setLoader(false);
                                  if(data.status === true){
                                      const { payed, status, discount, coupon } = data.data[0];
                                      setValue({ coupon, payed, status, reword });
                                      setDiscount(discount);
                                      setData(data.data[0]);
                                  }else{
                                    setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                                  }
                              });
                        }else{
                            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                        }
                    });

                }else{

                    const tprice = parseInt(data.totalPrice) + parseInt(data.vat) + parseInt(data.serviceCharge);
                    fetch(`${url}/sale/${id}`,{
                        method:"PUT",
                        body:  JSON.stringify({coupon, "payed":0, "prevPaid":0, reword:"", discount: Math.round((tprice*cp.discount)/100)}),
                        credentials: 'include'
                    }).then((data)=>data.json()).then((data)=>{
                        if(data.status === true){
                            fetch(`${url}/sale/${id}`,{
                                method:"GET",
                                credentials: 'include'
                              }).then((data)=>data.json()).then((data)=>{
                                setLoader(false);
                                  if(data.status === true){
                                      const { payed, status, discount, coupon, reword } = data.data[0];
                                      setValue({ coupon, payed, status, reword });
                                      setDiscount(discount);
                                      setData(data.data[0]);
                                  }else{
                                    setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                                  }
                              });
                        }else{
                            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                        }
                    });

                }

                setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Successful" message={cp.message} />]);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={cp.message} />]);
            }
        });
    }


  useEffect(()=>{
    setLoader(true)
    fetch(`${url}/sale/${id}`,{
      method:"GET",
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        setLoader(false)
        if(data.status === true){
            const { payed, status, discount, coupon } = data.data[0];
            setValue({ coupon, payed, status });
            setDiscount(discount);
            setData(data.data[0]);
        }else{
          setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    });
// eslint-disable-next-line
  },[id]);

  function checkReword(rw){
    fetch(`${url}/reword/check`,{
        method:"POST",
        body:  JSON.stringify({name:reword, cart: data.cart}),
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){

            setDiscount(data.discount);
            setCoupon("");

            fetch(`${url}/sale/${id}`,{
                method:"PUT",
                body:  JSON.stringify({coupon:"", discount: data.discount, reword}),
                credentials: 'include'
            }).then((data)=>data.json()).then((data)=>{
                if(data.status === true){
                    fetch(`${url}/sale/${id}`,{
                        method:"GET",
                        credentials: 'include'
                      }).then((data)=>data.json()).then((data)=>{
                          setLoader(false);
                          if(data.status === true){
                              const { payed, status, discount, coupon, reword } = data.data[0];
                              setValue({ coupon, payed, status, reword });
                              setDiscount(discount);
                              setData(data.data[0]);
                          }else{
                            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                          }
                      });
                }else{
                    setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                }
            });

            setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Successful" message={data.message} />]);
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
        }
    })
  }


  useEffect(()=>{
    
    setValue({...value, payed: parseInt(data.due) - parseInt(discount) - parseInt(data.payed)});

  }, [data, discount,])



  return (
    <>
        <Form1>
            <div className= " fixed top-16 right-2 ">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            {
                loader?<div className=' absolute w-full flex justify-center mt-5 '><Loader /></div>:<></>
            }
            <div className=" flex flex-wrap items-center gap-4 text-white text-lg">
                <div>
                    <span className=" font-bold">Total Price: </span>
                    <span>{data.totalPrice}৳</span>
                </div>
                <div>
                    <span className=" font-bold">Tax/Vat: </span>
                    <span>{data.vat}৳</span>
                </div>
                <div>
                    <span className=" font-bold">Service Charge: </span>
                    <span>{data.serviceCharge}৳</span>
                </div>
                {
                    (discount > 0 && data.coupon !== "" )?
                    <div>
                        <span className=" font-bold">Coupon: </span>
                        <span>{data.coupon}</span>
                    </div>:<></>
                }
                {
                    (discount > 0 && data.reword !== "")?
                    <div>
                        <span className=" font-bold">Reword: </span>
                        <span>{data.reword}</span>
                    </div>:<></>
                }
                <div>
                    <span className=" font-bold">Discount: </span>
                    <span>{discount}৳</span>
                </div>
                <div>
                    <span className=" font-bold">Payable: </span>
                    <span>{data.totalPrice - discount}৳</span>
                </div>

                <div>
                    <span className=" font-bold">Paid: </span>
                    <span>{data.payed}৳</span>
                </div>

                <div>
                    <span className=" font-bold">Due: </span>
                    <span>{data.due - discount - data.payed}৳</span>
                </div>
            </div>
            <Input1 onChange={(e)=>setCoupon(e.target.value)} type="text" name="coupon" placeholder="Enter coupon code:" value={coupon} label="Coupon:" />
            <Button1 click={checkCoupon} name="Check" />

            <Input1 onChange={(e)=>setReword(e.target.value)} type="text" name="reword" placeholder="Enter reword code:" value={reword} label="Reword:" />
            <Button1 click={checkReword} name="Check" />
        </Form1>

        <Form1 title="Update Order">
            {/* <Input1 onChange={set} type="number" name="discount" placeholder="Enter food discount:" value={value.discount} label="Discount:" /> */}
            <Input1 onChange={(e)=> setGet(e.target.value)} type="number" name="payed" placeholder="Enter total paid:" value={get} label="Total took:" />
            <Input1 onChange={set} type="number" name="payed" placeholder="Enter total paid:" value={get - value.payed} label="Total returnable:" />
            <Input1 onChange={set} type="number" name="payed" placeholder="Enter total paid:" value={value.payed} label="Total keepable:" />
            <Select onChange={set}  name="status" value={value.status}  id="input4"  label="Update status :">
                <Option value="" disable={true}>Select status</Option>
                <Option value="pending" >Pending</Option>
                <Option value="cooking" >Cooking</Option>   
                <Option value="served" >Served</Option>   
                <Option value="Paid" >Paid</Option>
                <Option value="complete" >Completed</Option>
                <Option value="cancel" >Canceled</Option>         
            </Select>
            <Button1 click={send} name="Save" />
        </Form1>
    </>
  )
}


export default UpdateOrder;