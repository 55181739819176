import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Input1, Textarea, CheckBox, Form1 } from  '../../components/Input';
import url from '../../url';

function AddReward() {
  const [value, setValue] = useState({name:'', description:'', discount:0});
  const [serviceGroup, setServiceGroup] = useState([]);
  const [category, setCategory ] = useState([]);
  const navigate = useNavigate();



  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function setCheckBox(e){

    if(category.includes(e.target.id)){
      const c = category.filter((data)=>{
        return(e.target.id !== data);
      });
      setCategory(c);
    }else{
      setCategory([...category, e.target.id])
    }
    
  }

  function setCheckBoxAll(){
    if(category.length === serviceGroup.length){
      setCategory([])

    }else{
      const c = serviceGroup.map((data)=>{
        return(data._id);
      });
      setCategory([...c])
    }
    
  }

  useEffect(()=>{

    fetch(`${url}/category?page=1&limit=0`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
                
        if(data.status === true){
            setServiceGroup(data.result.data);
        }
    });

  },[]);


  function send(){

    fetch(`${url}/reword`,{
        method:"POST",
        body: JSON.stringify({...value,category}),
        credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
        if(data.status === true){
          navigate(-1);
        }
    });

  }


  return (
    <Form1 title="Add Reward">
        <Input1 onChange={set} name="name" placeholder="Enter reward code:" value={value.name} label="Reward code:" />
        <Textarea onChange={set} name="description" placeholder="Enter reward code description:" value={value.description} label="Coupon description:" />
        <h3 className=" text-slate-200">Select category:</h3>
        <div className=" w-full flex flex-wrap gap-x-10">
        <CheckBox onChange={setCheckBoxAll}  name="all" id="all" label="All" checked={category.length === serviceGroup.length} />
          {
            serviceGroup.map((data, index)=>{
                return(
                  <CheckBox key={index} onChange={setCheckBox}  name={data.name} value={value.reword} id={data._id} label={data.name} checked={category.includes(data._id)} />
                )
            })
          }
        </div>
        <Input1 onChange={set} type="number" name="discount"  id="discount" placeholder="Discount amount:" value={value.discount}   label="Discount (%):" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddReward;