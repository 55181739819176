import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { Alert2, AlertContainer } from '../components/Alert';
import url from '../url';

function LogIn(){
    const [loader, setLoader] = useState(false);
    const [value, setValue] = useState({email:'', password:''});
    const [alert, setAlert] = useState([]);
    const navigate = useNavigate();

    function set(e){
        setValue({...value, [e.target.name]:e.target.value});
    }



    function send(){
        if(value.email === '' || value.password === ''){
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="All fields are required." />]);
        }else{
            setLoader(true);
            fetch(`${url}/user/login`,{
                method:'POST', 
                body:JSON.stringify(value),
                credentials: 'include',
				mode:'cors'

            }).then((data)=>data.json(data)).then((data)=>{
                setLoader(false);
                if(data.status === true){
                    // document.cookie = `auth = ${JSON.stringify({id:data.id, login:data.login, name:data.name, role:data.role, img:data.img, token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.vy7y8TnNQT4jliUYDJgo8tAa4Vt0KeHrBCU65eMbYDQ"})} ; max-age=3400; path=/`;

                    navigate('/');
					
                }else{
                    setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                }
            })
        }
    }


    return(
        <section className=" w-full h-screen flex flex-col items-center justify-center bg-slate-100 p-2">
            {
                loader?<Loader />:<></>
            }
            <div className= " absolute top-10 right-2">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            <form className=" w-[400px] h-[300px] p-4 bg-slate-100 flex flex-col gap-4 shadow2">
                <h1 className=" text-xl text-center text-rose-700 font-bold">Tehari n Hurry</h1>
                <label htmlFor="email"></label>
                <input onChange={(e)=>set(e)} type="email" name="email" id="email" placeholder="Enter your email:" value={value.email} className=" w-full px-3 py-[5px]  border-none outline-none shadow1" />
                <input onChange={(e)=>set(e)} type="password" name="password" id="password" placeholder="Enter your password:" value={value.password} className=" w-full px-3 py-[5px]  border-none outline-none shadow1" />
                <input onClick={send} type="button" value="Log In" className=" w-full px-3 py-[5px] text-rose-800 font-bold  border-none outline-none shadow3 transition-all duration-500 cursor-pointer ease-linear" />
            </form>

        </section>
    )
}

export default LogIn;