import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import url from '../../url';

function PrintToken(){

    const [purchase, setPurchase] = useState({_id:'', cart:[], customer:{}, receivable:0, received:0, due:0, customerName:"", customerEmail:"", customerPhone:"", customerAddress:"", totalPrice: 0, discount:0, quantity:0, date:''});
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(()=>{

        fetch(`${url}/sale/${id}`,{credentials: 'include'}).then((purchase)=>purchase.json()).then((purchase)=>{

            setPurchase(purchase.data[0]);

            setTimeout(() => {
                window.print();
                navigate(-1)
            }, 500);
            
        });

    },[id, navigate]);



    return(
        <div className=" w-full h-full p-10 ">
            <div className={` grow p-2 flex flex-col `}>
                <div><span className=" font-bold">Token ID: </span><span>{purchase.token}</span></div>
                <div><span className=" font-bold">Order Time: </span><span>{new Date(purchase.date).toLocaleString()}</span></div>
                <div><span className=" font-bold">Customer Name: </span><span>{purchase.customerName}</span></div>
                <div><span className=" font-bold">Customer Type: </span><span>{purchase.customerType}</span></div>
                <div><span  className=" font-bold">Table: </span><span>{purchase.table}</span></div>
            </div>
            <div className="border-b text-center font-bold">Order</div>
            <div className=" p-5 flex flex-col rounded-md justify-center relative">
                    {
                        purchase.cart.map((data,index)=>{
                            return(
                                <div key={index}><span>{index+1}.</span><span className=" pl-5">{data.name}</span><span className=" pl-5">x{data.quantity}</span></div>
                            )
                        })
                    }
                </div>

        </div>
    );
}

export default PrintToken;