import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import url from '../../url';

const COLORS = ['#ff0000', '#007f10'];


function ThisMonthExpenseG (){

  const [data, setData] = useState([]);

  useEffect(()=>{
    fetch(`${url}/report/thisMonthExpenseG `,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{
        setData([{name:"Total Amount", data:data.data[0].totalAmount}]);
    });
  },[]);


    return (
    <div className=" w-[300px]">
    <h1 className='py-6 md:-mb-8 mt-10 text-center text-xl font-bold text-nav2'>This Month Expense </h1>
    <div className=' w-full h-[300px] md:h-[200px] flex flex-col md:flex-row'>
      <ResponsiveContainer >
        <PieChart >
            
          <Pie
            data={data}
            cx="50%"
            cy="40%"
            labelLine={true}
            label
            outerRadius={50}
            fill="#007f10"
            dataKey="data"
            
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
            <Tooltip />
        </PieChart>

      </ResponsiveContainer>

      </div>

      <div className=" w-full -mt-5  md:-mt-10 flex flex-col md:flex-row gap-2 items-center justify-center">
        <div className=" mt-2 flex items-center justify-center">
            <div className=' w-6 h-6 bg-[#ff0000] rounded-full'></div>
            <span className=" pl-2 text-[#ff0000]">Total Amount(৳)</span>
        </div>
      </div>
      </div>
    );
  }

  export default ThisMonthExpenseG ;