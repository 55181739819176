import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { Counter, Edit, Invoice, View } from '../../components/Button';
import Table, { Tr, Td } from '../../components/Table';
import Loader from '../../components/Loader';
import url from '../../url';

function ThisWeekExpense() {

const [sale, setSale] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const page = useSelector((state)=>state.page);
const limit = useSelector((state)=>state.limit);
const [loader, setLoader] = useState(true);
const [total, setTotal] = useState(0);


useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/report/thisWeekExpense?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setSale(data.result);
                let total = 0
                data.result.data.map((data)=>{
                    total = total + data.amount;
                    setTotal(total);
                })
            }
        });
    }

    return () => setSale({data:[],next:{},previous:{}});

},[page,src, limit]);


function search(e){
    setSrc(e.target.value);
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="" name="" rowNames={["#","Name","Description", "Amount","Category","By","Date",]} srcVal={src} srcFunc={search} data={sale} width='w-full'>
                {
                    sale.data.map(({_id, name, description, amount, expenseCategory, by, date}, index)=>{
                        const d = new Date(date);
                        return(
                            <Tr key={index}>
                                    <Td>{((sale.previous.page*sale.previous.limit)+1)+index}</Td>
                                    <Td>{name}</Td>
                                    <Td width='w-40'>{description}</Td>
                                    <Td >{amount} ৳</Td>
                                    <Td>{expenseCategory[0].name}</Td>
                                    <Td >{by}</Td>
                                    <Td>{d.toLocaleDateString()}</Td>
                                    <Td>
                                        {/* <Edit to={"/editExpense/"+_id} /> */}
                                        {/* <Delete id={_id} click={delet} /> */}
                                    </Td>
                                </Tr>
                        );
                    })
                }
                
                <tr className=" bg-slate-400 text-black">
        <td className="  font-bold text-center">Total</td>
        <td></td>
        <td></td>
        <td className=" border-x border-black text-center font-bold">{total}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
            </Table>
}</>)
}

export default ThisWeekExpense;