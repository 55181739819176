import React, {  useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import url from '../../url'
import { useSelector, useDispatch } from 'react-redux';
import { ADD_PAGE, } from '../../store/actions/paginate';

function Kitchen(){
    const [sale, setSale] = useState({data:[],next:{},previous:{}});
    const [data, setData] = useState({cart:[]});
    // const [src, setSrc] = useState('');
    const page = useSelector((state)=>state.page);
    // const limit = useSelector((state)=>state.limit);
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();

    useEffect(()=>{

            setLoader(true);
            fetch(`${url}/sale/new?page=${page}&limit=${12}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            
                setLoader(false);
                if(data.status === true){
                    setSale(data.result);
                }
            });
    
        return () => setSale({data:[],next:{},previous:{}});
    
    },[page]);


    const buttons = [];

    for(let i = 1; i <= sale.totalPage; i++){
        buttons.push(<button key={i} onClick={()=>dispatch(ADD_PAGE(i))} className={` ${i=== (sale.previous.page+1)?' text-green-400':' text-white' } h-11 w-11 mr-1`}>{i}</button>)
    }

    if(sale.totalPage === 1){
        dispatch(ADD_PAGE(1));
    }


    
    return(
        <>
        {
            (data._id !== undefined)?
            <div className=" fixed top-0 left-0 w-full h-screen ml-8 bg-slate-400/60 flex justify-center items-center">
  
                <div className=" min-w-[300px] min-h-[350px] p-5 bg-slate-100 flex flex-col rounded-md justify-center relative">
                <button onClick={()=>setData({cart:[]})} className=" absolute top-1 right-3 text-red-700" name="close"><i className="fa-solid fa-xmark text-2xl"></i></button>
                    {
                        data.cart.map((data,index)=>{
                            return(
                                <div key={index}><span>{index+1}.</span><span className=" pl-5">{data.name}</span><span className=" pl-5">x{data.quantity}</span></div>
                            )
                        })
                    }
                </div>
            </div>:<></>
        }

        {
        loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
            <div className=" w-full h-max bg-slate-100 p-3 flex flex-wrap justify-center gap-4">
                {

                    // console.log(sale);
                sale.data.map((data, index)=>{
                    if(data.status === "complete" || data.status === "cancel"){
                        return(<div key={index}></div>)
                    }else{
                        return(
                            <div key={index} className={` w-[250px]  h-[200px] grow p-2 flex flex-col ${(data.status === "pending")?"bg-yellow-500 rounded-md":""} ${(data.status === "served")?"bg-green-500 rounded-md":"" } ${(data.status === "complete")?" bg-blue-700 rounded-md":"" }${(data.status === "cooking")?"bg-orange-600 rounded-md":"" } ${(data.status === "Paid")?"bg-sky-400 rounded-md":"" } ${(data.status === "cancel")?"bg-rose-700 rounded-md":"" }`}>
                                <div><span className=" font-bold">Token ID: </span><span>{data.token}</span></div>
                                <div><span className=" font-bold">Time: </span><span>{new Date(data.date).toLocaleString()}</span></div>
                                <div><span className=" font-bold">Customer: </span><span>{data.customerName}</span></div>
                                <div><span className=" font-bold">Customer Type: </span><span>{data.customerType}</span></div>
                                <div><span  className=" font-bold">Status: </span><span className=" capitalize">{data.status}</span></div>
                                <div><span  className=" font-bold">Table: </span><span>{data.table}</span></div>
                                <div className=" flex gap-4 justify-center text-slate-700">
                                    <button onClick={()=> setData(data)} name='view'><i className="fa-solid fa-eye text-2xl"></i></button>
                                    <Link to={`/token/${data._id}`}><i className="fa-solid fa-print text-2xl"></i></Link>
                                </div>
                            </div>
                        )
                    }

                })
                }

            <div className={` w-full h-auto mb-10 bg-dark-blue-1 mx-auto rounded-bl-md rounded-br-md text-white flex justify-between flex-col sm:flex-row`}>
                    <div className=' w-[600px] lg:w-[1200px] flex flex-wrap'>
                    {
                        (sale.previous.page === 0)? <button className=' h-11 px-2 mr-1 text-gray-400 cursor-not-allowed'>Previous</button>:<button onClick={()=>dispatch(ADD_PAGE(sale.previous.page))} className=' h-11 px-2 mr-1'>Previous</button>
                    }
                        <div  className=' flex flex-wrap'>
                            {
                                buttons
                            }
                        </div>
                        {
                            (sale.next.page === 0)?  <button className='h-11 px-2 text-gray-400 cursor-not-allowed'>Next</button>: <button onClick={()=>dispatch(ADD_PAGE(sale.next.page))} className='h-11 px-2'>Next</button>
                        }
                        
                    </div>
                    <div className=' w-max shrink-0 p-2 sm:pl-8 py-2 mt-0.5'>Showing {(sale.previous.page*sale.previous.limit)+1} to { (sale.next.page === 0)? sale.totalData : (sale.previous.page+1)*sale.previous.limit} of {sale.totalData} entries</div>
                    
                </div>

            </div>

        }
        
        
        </>
    )
}


export default Kitchen;