import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Input1, Option, Select, Form1 } from '../../components/Input';
import url from '../../url';
import { Alert2, AlertContainer } from '../../components/Alert';
import Loader from '../../components/Loader';

function AddBooking(){
    const [value, setValue] = useState({customer:'', table:'', time:''});
    const [customer, setCustomer] = useState([]);
    const [table, setTable] = useState([]);
    const [alert, setAlert] = useState([]);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    
    function set(e){
        setValue({...value, [e.target.name]:e.target.value});
    }

    function send(){

        fetch(`${url}/booking`,{
          method:"POST",
            body:  JSON.stringify(value),
          credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
          if(data.status === true){
            navigate(-1);
          }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
          }
        });
  
    }


    useEffect(()=>{


        fetch(`${url}/customer`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setCustomer(data.result.data);
        });

        fetch(`${url}/table`,{method:"GET", mode:"cors", credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            setTable(data.result.data);
        });

    },[]);

    return(
        <Form1 title="Add Booking">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        {
            loader?<Loader />:<></>
        }
        <Select onChange={set}  name="customer" value={value.customer}  id="customer"  label="Select customer :">
          <Option value="" disable={true}>Select a customer</Option>
          {
              customer.map((data, index)=>{
                  return(
                      <Option key={index} value={data._id}>{data.name}</Option>
                  )
              })
          }
        </Select>
        <Select onChange={set}  name="table" value={value.table}  id="table"  label="Select table :">
          <Option value="" disable={true}>Select a table</Option>
            {
                // eslint-disable-next-line array-callback-return
                table.map((data, index)=>{

                    if(data.status === "available" ){
                        if(data.booking === false){
                            return(
                                <option key={index} value={data._id}>{data.name}</option>
                            );
                        }else{

                            if(new Date().getTime() < new Date(data.bookingTime).getTime()){
                                return(
                                    <option key={index} value={data._id}>{data.name}</option>
                                );
                            }
                        }
                    }

                })
            }
        </Select>
        <Input1 onChange={(e)=>{setValue({...value, time: new Date(e.target.value).getTime()});}} type="datetime-local" name="time" placeholder="Enter booking time:" value={value.by} label="Booking time:" />
        <Button1 click={send} name="Save" />
    </Form1>
    )
}

export default AddBooking;