import React from 'react';
import { useState, useEffect } from 'react';
import url from '../../url';
import Loader from '../../components/Loader';
import { Input1, Option, Select } from '../../components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TO_CART_G, CANCEL_ORDER_G, REMOVE_FROM_CART_G } from '../../store/actions/goodsCart';
import { Alert2, AlertContainer } from '../../components/Alert';
import { useNavigate } from 'react-router-dom';

function PurchaseGoods(){
    const [goods, setGoods] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [loader, setLoader] = useState(true);
    const [supplerS, setSupplierS] = useState("");
    const [paid, setPaid] = useState(0);
    const [total, setTotal] = useState(0);
    const [product, setProduct] = useState({id:'', name:'', price:0, unit:0, inStock:0, quantity:0});
    const [alert, setAlert] = useState([]);
    const dispatch = useDispatch();
    const goodsCart = useSelector((state)=>state.goodsCart);
    const navigate = useNavigate();


    
    useEffect(()=>{

        setLoader(true);
        fetch(`${url}/goods`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setGoods(data.result.data);
            }
        });

        fetch(`${url}/supplier`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
        
            setLoader(false);
            if(data.status === true){
                setSupplier(data.result.data);
            }
        });
    
    },[]);

    useEffect(()=>{
        const t = goodsCart.reduce((total, {price, quantity})=>{
            return(total + price * quantity)
        }, 0);

        setTotal(t);
        setPaid(t);
    },[goodsCart]);

    function isInCart(id){
        const gd = goodsCart.filter(({id:idd})=>{
            return(id === idd);
        });

        if(gd.length > 0){
            return(true);
        }else{
            return(false);
        }
    }


    function send(){
        if(supplier !== "" && goodsCart.length > 0){
            fetch(`${url}/purchase`, { method:"POST", body:JSON.stringify({supplier:supplerS, paid, payable:total, cart:goodsCart}), mode:'cors', credentials:'include'}).then((data)=>data.json()).then((data)=>{
                setProduct({id:'', name:'', price:0, inStock:0, quantity:0});
                dispatch(CANCEL_ORDER_G());
                setSupplierS("");
                navigate("/purchase")
            });
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="All  fields are required!" />]);
        }

    }


    return(
        <>
            {
                loader?<Loader />:<></>
            }
            <div className= " absolute top-10 right-2">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            {
                (product.id !== "")?
                <div className=" absolute w-full h-full top-0 left-0 bg-blue-400/30 flex items-center justify-center">
                    <div className=" w-[400px] h-[300px] rounded-md bg-slate-100 shadow-lg">
                        <button onClick={()=>setProduct({id:'', name:'', price:0, inStock:0, quantity:0})} type="button" className=" text-lg bg-red-600 hover:bg-red-800 px-3 rounded-md float-right mt-1 mr-1 text-white"><i className="fa-solid fa-x"></i></button>
                        <div className=" w-full h-full p-4 mt-10">
                           <div>
                                <span className=" font-bold" >Name: </span>
                                <span>{product.name}</span>
                            </div> 
                            <div>
                                <span className=" font-bold" >In Stock: </span>
                                <span>{product.inStock} {product.unit}</span>
                            </div> 
                            <div>
                                <span className=" font-bold">Item Price:</span>
                                <Input1 onChange={(e)=>setProduct({...product, price:parseInt(e.target.value)})} type="number" value={product.price} />
                            </div>
                            <div>
                                <span className=" font-bold">Item Quantity:</span>
                                <Input1 onChange={(e)=>setProduct({...product, quantity:parseInt(e.target.value)})} type="number" value={product.quantity} />
                            </div>
                            <div>
                                <button onClick={()=>{ dispatch(ADD_TO_CART_G(product)); setProduct({id:'', name:'', price:0, inStock:0, quantity:0}); }} className="mt-5 bg-blue-700 hover:bg-blue-800 text-white px-3 py-[3px] text-lg rounded-md " type='button'>Add</button>
                            </div>
                        </div>
                    </div>
                </div>:<></>
            }

            <div className=" w-full h-full bg-slate-50">
                <div className=" w-full h-[calc(100%-80px)] bg-slate-50 p-3 shadow flex gap-5">
                    <div className=" w-[250px] h-full py-3 px-4 shadow1 flex flex-col gap-4 items-center overflow-auto">
                        
                        {
                            goods.map((data, index)=>{
                                return(
                                    isInCart(data._id)?
                                    
                                    <div key={index} className={" w-full h-max shadow1 text-center py-2 cursor-not-allowed"}>{data.name}</div>:
                                    <div key={index} onClick={()=>{setProduct({id:data._id, name:data.name, price:data.price, inStock:data.quantity, unit:data.unit, quantity:0})}} className={"w-full h-max shadow3 text-center py-2 cursor-pointer"}>{data.name}</div>
                            )
                            })
                        }
                    </div>
                    <div className=" w-[calc(100%-255px)] h-full shadow1 p-2 overflow-auto">
                        <table className=" w-full ">
                            <thead>
                                <tr className=" border-b border-slate-400">
                                    <th className=' text-center'>#</th>
                                    <th className=' text-center'>Name</th>
                                    <th className=' text-center'>Unit Price</th>
                                    <th className=' text-center'>Quantity</th>
                                    <th className=' text-center'>Total</th>
                                    <th className=' text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    goodsCart.map((data, index)=>{
                                        return(
                                            <tr key={index} className=" border-b border-slate-400">
                                                <td className=' text-center'>{index + 1}</td>
                                                <td className=' text-center'>{data.name}</td>
                                                <td className=' text-center'>{data.price}</td>
                                                <td className=' text-center'>{data.quantity} {data.unit}</td>
                                                <td className=' text-center'>{data.price * data.quantity}</td>
                                                <td className=' text-center flex gap-2 justify-center'>
                                                    <button onClick={()=>setProduct({id:data.id, name:data.name, price:data.price, inStock:data.quantity, unit:data.unit, quantity:data.quantity})}  className=" text-green-600"><i className="fa-solid fa-pen-to-square"></i></button>
                                                    <button onClick={()=>dispatch(REMOVE_FROM_CART_G({id:data.id}))}  className=" text-red-600"><i className="fa-solid fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className=" w-full h-[80px] px-2 pb-2 flex gap-4 items-center">
                    <div className=" w-[200px]">
                        <span className=" font-bold">Supplier :</span>
                        <Select onChange={(e)=> setSupplierS(e.target.value)} name="supplier" value={supplerS} >
                            <Option value="">Select a Supplier</Option>
                            {
                                supplier.map((data, index)=>{
                                    return(<Option key={index} value={data._id}>{data.name}</Option>)
                                })
                            }
                        </Select>
                    </div>
                    <div className=" w-[200px] pt-6">
                        <span className=" font-bold">Total : </span>
                        <span>{total}</span>
                    </div>
                    <div>
                        <span className=" font-bold">Paid :</span>
                        <Input1 onChange={(e)=>setPaid(e.target.value)} type="number" value={paid} />
                    </div>
                    <div>
                        <button onClick={()=> dispatch(CANCEL_ORDER_G())} className=" mt-8 bg-red-700 hover:bg-red-800 text-white px-3 py-[3px] text-lg rounded-md " type='button'>Clear</button>
                        <button onClick={send} className=" mt-8 ml-4 bg-blue-700 hover:bg-blue-800 text-white px-3 py-[3px] text-lg rounded-md " type='button'>Send</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PurchaseGoods;